import React, { useState } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Grid, Button, Typography, Divider, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ProfilePhoto from '../../components/ProtectedInfo/ProfilePhoto';
import UploadPictureButton from '../../components/ProtectedInfo/UploadPictureButton';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/selectors/user';
import AccountEditForm from '../../components/Account/EditForm/AccountEditForm';
import NotificationSettings from '../../components/Account/NotificationSettings';
import Membership from './Membership';
import userPlanMessage from './utils/userPlanMessage';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 0,
    '& > *:first-child': {
      [theme.breakpoints.up('md')]: {
        borderRight: `1px solid ${theme.palette.grey[300]}`,
      },
    },
  },
  container: {
    [theme.breakpoints.down('md')]: {
      padding: 'unset !important',
      margin: '0 auto !important',
    },
  },
  photoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(5),
    '& > button': {
      fontWeight: 600,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  profilePhoto: {
    marginRight: theme.spacing(3),
  },
  gridMembership: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 !important',
    },
  },
  dividerMembership: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: theme.spacing(4),
    },
  },
  membership: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
    '& a': {
      marginLeft: theme.spacing(3),
      textDecoration: 'none',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      '& a': {
        marginLeft: 0,
        marginTop: theme.spacing(2),
      },
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  title: {
    fontWeight: 600,
  },
  infoContainer: {
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(1),
      },
    },
    '& > h5': {
      fontSize: '1.2em',
      marginBottom: theme.spacing(7),
    },
    '& > button': {
      fontWeight: 600,
    },
    '& > p': {
      marginBottom: theme.spacing(0.5),
    },
    '& .MuiCircularProgress-root': {
      marginLeft: theme.spacing(1),
      verticalAlign: 'middle',
    },
  },
}));

const Label = ({ label, value }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="body1" className={classes.title}>
        {label}
      </Typography>
      <Typography variant="h5">{value}</Typography>
    </>
  );
};

export default ({ currentCoupon, isLoadingCurrentCoupon }) => {
  const classes = useStyles();
  const user = useSelector(getUser);
  const [isUpdating, setIsUpdating] = useState(false);

  return (
    <Grid container spacing={8} className={classes.root}>
      <Grid item xs={12} md={5} lg={4} className={classes.container}>
        <div className={classes.photoContainer}>
          <ProfilePhoto className={classes.profilePhoto} />
          <UploadPictureButton />
        </div>
        <div className={classes.infoContainer}>
          {!isUpdating ? (
            <>
              <Label label="Name" value={`${user.firstName} ${user.middleName || ''} ${user.lastName}`} />
              <Label label="Your Primary Email" value={user.primaryEmail} />
              <Label label="Password" value="**********" />
              <Button
                variant="contained"
                onClick={() => {
                  setIsUpdating(true);
                }}
              >
                Edit Info
              </Button>
            </>
          ) : (
            <AccountEditForm
              onComplete={() => {
                setIsUpdating(false);
              }}
            />
          )}
        </div>
      </Grid>

      <Grid item xs={12} md={7} lg={8} className={classes.gridMembership}>
        <Divider className={classes.dividerMembership} />
        <div className={classes.membership}>
          <Switch>
            <Route exact path="/settings">
              <div>
                <Typography variant="h5">Membership Plan</Typography>
                {isLoadingCurrentCoupon ? (
                  <CircularProgress size={20} style={{ margin: '8px' }} />
                ) : (
                  userPlanMessage(user, currentCoupon?.percent_off, currentCoupon?.amount_off)
                )}
              </div>

              <Link to="/settings/membership">
                <Button variant="contained" color="secondary">
                  Manage Membership
                </Button>
              </Link>
            </Route>
            <Route path="/settings/membership">
              <Membership />
            </Route>
          </Switch>
        </div>
        <Divider />
        <NotificationSettings />
      </Grid>
    </Grid>
  );
};
