import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Grid, TextField, Typography, CircularProgress, Button, InputAdornment, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { functions } from '../../firebase';
import useAsync from '../../hooks/useAsync';
import { setUserPlan } from '../../store/actions/user';
import { useDispatch } from 'react-redux';
import FormButtons from '../Auth/FormButtons';
import PlanPicker from './PlanPicker';
import googleTagManager from '../utils/googleTagManager';
import transactionTracking from '../utils/transactionEventData';
import { daysLeftInTrial } from '../../store/selectors/user';
import { useSelector } from 'react-redux';
import { createPaymentMethod } from '../../store/services/stripe';
import useFirestoreSubscribe from '../../hooks/useFirestoreSubscribe';
import CycleSelector from './CycleSelector';
import { useHistory } from 'react-router-dom';
import { priceAfterCoupon } from '../../components/utils/priceAfterCoupon';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  subTitles: {
    marginBottom: theme.spacing(1),
  },
  stripeInput: {
    border: '1px solid #CDCDCD',
    borderRadius: 25,
    padding: '21px 20px',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  agreementText: {
    textAlign: 'center',
    fontWeight: 500,
    margin: theme.spacing(2),
  },
  error: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    fontWeight: 500,
    color: 'red',
  },
  couponCode: {
    margin: theme.spacing(2, 5),
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      textAlign: 'center',
    },
  },
}));

export const BillingForm = ({ sites }) => {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [coupon, setCoupon] = useState('');
  const days = useSelector(daysLeftInTrial);
  const [open, setOpen] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [couponValidated, setCouponValidated] = useState(false);
  const [percentOff, setPercentOff] = useState(0);
  const [amountOff, setAmountOff] = useState(0);
  const [couponDuration, setCouponDuration] = useState('');
  const [cycle, setCycle] = useState('annual');
  const [type, setType] = useState('premium');
  const [plans, isLoadingPlans] = useFirestoreSubscribe('plans');
  const [clickedTrial, setClickedTrial] = useState(false);
  const history = useHistory();

  const [{ isLoading, error }, createStripeCustomer] = useAsync(async event => {
    event.preventDefault();

    const paymentMethod = await createPaymentMethod({ stripe, elements });

    const result = await functions.httpsCallable('createSubscription')({
      paymentMethod,
      coupon,
      plan: `${type}:${cycle}`,
    });

    dispatch(setUserPlan(result.data));
    googleTagManager('new_subscription');
    transactionTracking(result.data);
  });

  const [{ isLoading: isTrialLoading }, createTrial] = useAsync(async event => {
    event.preventDefault();
    if (clickedTrial) {
      return;
    }
    setClickedTrial(true);
    const result = await functions.httpsCallable('createTrial')();
    dispatch(setUserPlan(result.data));
    googleTagManager('new_trial');
    history.replace('/alerts');
  });

  const [{ isLoading: isLoadingCoupons, error: couponError }, applyCoupon] = useAsync(async () => {
    const activeCoupon = await functions.httpsCallable('fetchActiveCoupons')(coupon);
    if (activeCoupon?.data) {
      setCoupon(activeCoupon.data);
      const { percent_off, amount_off, duration } = activeCoupon.data;
      setPercentOff(percent_off || 0);
      setAmountOff(amount_off || 0);
      setCouponDuration(duration);
      setCouponValidated(true);
    } else {
      setInvalidCode(true);
    }
  });

  const resetCoupon = () => {
    setCoupon({});
    setPercentOff(0);
    setAmountOff(0);
    setCouponValidated(false);
  };

  useEffect(() => {
    resetCoupon();
    // eslint-disable-next-line
  }, [invalidCode]);

  if (isLoadingPlans) {
    return <CircularProgress />;
  }

  const selectedPlan = plans.find(plan => plan.cycle === cycle && plan.type === type);

  return (
    <form onSubmit={createStripeCustomer} id="billingForm">
      <CycleSelector setSelectedCycle={setCycle} selectedCycle={cycle} />
      <Grid container justify="center">
        <PlanPicker
          onChange={setType}
          selectedCycle={cycle}
          selectedType={type}
          plans={plans.filter(plan => plan.cycle === cycle).sort((a, b) => a.price - b.price)}
          sites={sites}
          percentOff={percentOff}
          amountOff={amountOff}
          couponDuration={couponDuration}
        />
      </Grid>
      <Grid container spacing={2} justify="center" className={classes.container}>
        <Grid item xs={12} md={6}>
          <Typography className={classes.subTitles} variant="h6">
            Enter your Payment Information
          </Typography>
          <CardElement className={classes.stripeInput} />
          {error && (
            <div id="error" className={classes.error}>
              There was a error with your card
            </div>
          )}
        </Grid>

        <Grid item xs={12} md={6} className={classes.cuponInput}>
          <Typography className={classes.subTitles} variant="h6">
            Coupon
          </Typography>
          {!invalidCode && couponValidated ? (
            <>
              <Typography variant="h6" color="secondary" className={classes.couponCode}>
                {coupon?.name}
                <IconButton
                  aria-label="remove"
                  onClick={() => resetCoupon()}
                  style={{ backgroundColor: 'transparent', marginBottom: '4px' }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Typography>
            </>
          ) : (
            <TextField
              fullWidth
              onChange={e => {
                setInvalidCode(false);
                setCoupon(e.target.value);
                setOpen(true);
              }}
              placeholder="Coupon"
              id="coupon"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {(open || isLoadingCoupons) && (
                      <Button
                        variant="contained"
                        style={{ padding: '8px' }}
                        disabled={isLoadingCoupons}
                        size="small"
                        color="secondary"
                        onClick={() => {
                          applyCoupon();
                          setOpen(false);
                        }}
                      >
                        {isLoadingCoupons ? <CircularProgress size={20} /> : 'Apply Coupon'}
                      </Button>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          )}
          {(couponError || invalidCode) && (
            <Typography className={classes.error} variant="subtitle2" color="error">
              Unable to apply coupon or coupon code is invalid
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={5}>
          <Typography className={classes.agreementText} variant="body1">
            You will be billed $
            {priceAfterCoupon(parseFloat(selectedPlan.price).toLocaleString(), amountOff, percentOff)} for{' '}
            {
              {
                monthly: ' one month ',
                annual: ' one year ',
                biennial: ' two years ',
              }[selectedPlan.cycle]
            }
            of Safe Shepherd protection. At the end of{' '}
            {
              {
                monthly: ' one month',
                annual: ' one year',
                biennial: ' two years',
              }[selectedPlan.cycle]
            }
            , you will be billed again unless you terminate service prior to the end of your billing cycle. You can
            cancel anytime.
          </Typography>
          <FormButtons
            isLoading={!stripe || isLoading || isTrialLoading}
            primaryText={'I Agree to the Terms & Activate Service'}
            secondaryText={!days && 'Or, skip choosing a plan now and start my 7-day free trial.'}
            secondaryId="skip"
            secondaryAction={createTrial}
          />
        </Grid>
      </Grid>
    </form>
  );
};
