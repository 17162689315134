import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  snackbar: {
    '& .MuiAlert-message': {
      width: '100%',
    },
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '10px',
    width: '62%',
    margin: theme.spacing(2, 'auto', 2, 'auto'),
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: theme.spacing(0),
      borderRadius: '0px',
    },
  },
  textContent: {
    color: 'white',
    textAlign: 'left',
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(6),
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(0),
      float: 'none',
      justifyContent: 'center',
    },
  },
}));
