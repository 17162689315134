import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Drawer,
  Hidden,
  Dialog,
  IconButton,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  Divider,
  useTheme,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import useIntersect from '../hooks/useIntersect';
import useWindowSize from '../hooks/useWindowSize';
import useQuery from '../hooks/useQuery';

import AlertList from '../components/Alert/List';
import AlertActionDialog from '../components/Alert/ActionDialog';
import ProfilePhoto from '../components/ProtectedInfo/ProfilePhoto';

import ProtectedInfo from '../components/ProtectedInfo';
import InsightCard from '../components/Insight/Card';
import InsightTopBar from '../components/Insight/TopBar';
import { useSelector } from 'react-redux';
import { getUser } from '../store/selectors/user';
import useFirebaseSubscribe from '../hooks/useFirestoreSubscribe';

import { ArrowBack as CollapseIcon, ArrowForward as ExpandIcon } from '@material-ui/icons';

import TrialAlert from '../components/TrialAlert';
import SubscriptionEndingAlert from '../components/SubscriptionEndingAlert';
import ViewingAsAlert from '../components/ViewingAsAlert';

import mixpanel from 'mixpanel-browser';

const DRAWER_WIDTH = 360;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  innerRoot: {
    flexGrow: 1,
    position: 'relative',
  },
  mainContent: {
    padding: `${theme.spacing(7)}px ${theme.spacing(10)}px`,
    maxWidth: 1100,

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6),
    },
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerOpen: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(5)}px`,
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(11) - 1,
    //Profile image and collapse button
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(1),
  },
  drawerPaper: {
    paddingRight: 0,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.navBg,
    justifyContent: 'space-between',
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1.5),
  },
  list: {
    '& .MuiListItem-root': {
      margin: `${theme.spacing(2)}px 0`,
      padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    },
  },
  collapseButton: {
    justifyContent: 'center',
    margin: `${theme.spacing(2)}px 0`,
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    '& .MuiListItemIcon-root': {
      justifyContent: 'center',
    },
    '& .MuiListItemText-root': {
      flex: 'unset',
    },
  },
  stickyDisplay: {
    height: theme.spacing(8),
    marginTop: -theme.spacing(8), // Counteract its own height, so it doesn't mess with layout when it appears.

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      // placeholder
      width: 40,
      height: 40,
      margin: '0 12px',
    },
  },
  insightCard: {
    padding: theme.spacing(3),
    display: 'flex',
    '& > *:first-child': {
      flex: 2,
    },
    '& > *:last-child': {
      flex: 1,
    },
  },
  dialog: {
    padding: theme.spacing(4),
    boxSizing: 'border-box',

    transform: 'translateX(100%)',
    transition: '225ms transform ease-in-out',
    '&.open': {
      transform: 'translateX(0)',
    },
    '&.isLargerScreen': {
      '& > h5': {
        justifyContent: 'end',
      },
      '& > div': {
        justifyContent: 'center !important',
      },
    },
  },
  dialogClose: {
    width: 'max-content',
    alignSelf: 'flex-end',
    marginBottom: -theme.spacing(7),
  },
  contentContainer: {
    overflow: 'hidden',
    overflowY: 'auto',
    paddingRight: theme.spacing(3),
  },
  profileIcon: {
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

export default () => {
  const classes = useStyles();
  const theme = useTheme();

  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [cardRef, intersectionEntry, isSupported] = useIntersect({ threshold: 0 });
  const windowSize = useWindowSize();
  const history = useHistory();
  const location = useLocation();
  const [open, setDrawerOpen] = useState(true);

  const user = useSelector(getUser);
  const [alerts, isAlertsLoading] = useFirebaseSubscribe('alerts', ['userId', '==', user.uid]);
  const [sites, isSitesLoading] = useFirebaseSubscribe('sites');
  const [handbooks, isHandbooksLoading] = useFirebaseSubscribe('handbooks');

  // Check if we need to show AlertActionDialog
  const queryDialogId = useQuery('dialog');
  const dialogAlert = !!queryDialogId && alerts.find(a => a.id === queryDialogId);

  // If windowSize is less than "lg", then show by default, otherwise show only when it scrolls past insight card.
  const shouldShowSticky =
    windowSize.width < theme.breakpoints.values.lg || (isSupported && !intersectionEntry.isIntersecting);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  // closes mobile privacy vault dialog when window-size changes
  useEffect(() => {
    const closeDialog = () => {
      setInfoDialogOpen(false);
    };
    mixpanel.track('Hit Milestone', { Event: 'workflow_visit_my_report' });
    window.addEventListener('resize', closeDialog);
    return () => window.removeEventListener('resize', closeDialog);
  }, []);

  if (isAlertsLoading || isSitesLoading || isHandbooksLoading) {
    return <></>;
  }

  const alertTypeCount = alertType => {
    return alerts.filter(alert => [...alertType].includes(alert.type)).length;
  };

  const totalVisiableCount = alerts.filter(alert =>
    ['unsupported', 'removed', 'requested', 'manual', 'adminOptOut'].includes(alert.type)
  ).length;

  const inProgressCount = alertTypeCount(['removed', 'adminOptOut', 'manual', 'requested', 'unsupported']);

  const alertTypePercentage = [
    alertTypeCount(['removed', 'adminOptOut', 'manual', 'requested']),
    alertTypeCount(['unsupported']),
  ];

  const percentage = Math.round((inProgressCount / alerts.length) * 100) || 0;

  return (
    <div className={classes.root}>
      <div className={classes.innerRoot}>
        <TrialAlert />
        <SubscriptionEndingAlert />
        <ViewingAsAlert />
        {/** This will not appear for browsers that don't support IntersectionObserver. Should be fine since this additional UI is only a nice-to-have. */}
        {shouldShowSticky && (
          <InsightTopBar
            alertsCount={totalVisiableCount}
            percentage={percentage}
            alertTypePercentage={alertTypePercentage}
            onProfileClick={() => setInfoDialogOpen(true)}
          />
        )}

        {/** Main content container */}
        <Container className={classes.mainContent}>
          <InsightCard
            user={user}
            sites={sites}
            alerts={totalVisiableCount}
            innerRef={cardRef}
            percentage={percentage}
            alertTypePercentage={alertTypePercentage}
          />
          <AlertList sites={sites} />
        </Container>
      </div>

      {/** Action dialog when there's a dialog query */}
      {!!dialogAlert && (
        <>
          <AlertActionDialog
            alert={dialogAlert}
            sites={sites}
            handbooks={handbooks}
            open={true}
            onClose={() => {
              history.push(location.pathname);
            }}
          />
        </>
      )}

      {/** "Privacy Vault" in the form of right-side-panel, used by larger screens */}
      <Hidden mdDown>
        <Drawer
          className={classnames({
            [classes.drawer]: true,
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          variant="persistent"
          anchor="right"
          open={true}
          classes={{
            paper: classnames({
              [classes.drawerPaper]: true,
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          {open ? (
            <div className={classes.contentContainer}>
              <ProtectedInfo />
            </div>
          ) : (
            <ProfilePhoto className={classes.profileIcon} onClick={!open && handleDrawerOpen} />
          )}

          <List
            className={classnames({
              [classes.list]: !open,
            })}
          >
            <Divider className={classes.divider} />
            <ListItem className={classes.collapseButton} button onClick={open ? handleDrawerClose : handleDrawerOpen}>
              {open && <ListItemText primary="Collapse" />}
              <ListItemIcon>{open ? <ExpandIcon /> : <CollapseIcon />}</ListItemIcon>
            </ListItem>
          </List>
        </Drawer>
      </Hidden>

      <Dialog
        id="privacy-vault-dialog" // for tests
        open={infoDialogOpen}
        fullScreen
        keepMounted
        classes={{
          paper: classnames({ [classes.dialog]: true, open: infoDialogOpen, isLargerScreen: !open && infoDialogOpen }),
        }}
        onClose={() => setInfoDialogOpen(false)}
      >
        <IconButton onClick={() => setInfoDialogOpen(false)} className={classes.dialogClose}>
          <CloseIcon />
        </IconButton>
        <ProtectedInfo />
      </Dialog>
    </div>
  );
};
