import React from 'react';
import { Hidden } from '@material-ui/core';
import TopNavDesktop from './Desktop';
import TopNavMobile from './Mobile';

export default () => (
  <>
    <Hidden smDown>
      <TopNavDesktop />
    </Hidden>
    <Hidden mdUp>
      <TopNavMobile />
    </Hidden>
  </>
);
