import { createStore, combineReducers, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import user from './reducers/user';

import userSaga from './sagas/user';

const reducers = combineReducers({
  user,
});

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(reducers, {}, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(userSaga);
