import { SET_USER, SET_USER_PII, SET_USER_PLAN, SET_VIEWING_USER } from '../actions/user';
import mixpanel from 'mixpanel-browser';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_USER: {
      if (!!action.user) {
        mixpanel.alias(action.user.uid);
        mixpanel.identify(action.user.uid);
      } else {
        mixpanel.reset();
      }
      if (action.user === false) {
        return action.user;
      }
      return { ...state, ...action.user };
    }
    case SET_VIEWING_USER: {
      return {
        ...state,
        viewingUser: {
          ...action.user,
          isViewing: true,
        },
      };
    }
    case SET_USER_PII: {
      mixpanel.track('Add PII');
      return {
        ...state,
        PII: {
          ...state.PII,
          [action.key]: action.val,
        },
      };
    }
    case SET_USER_PLAN: {
      return {
        ...state,
        plan: {
          ...state.plan,
        },
      };
    }
    default:
      return state;
  }
};
