import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { functions } from '../../firebase';
import useAsync from '../../hooks/useAsync';
import { getUser } from '../../store/selectors/user';
import Account from './Account';
import Membership from './Membership';
import userPlanMessage from './utils/userPlanMessage';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  mainContent: {
    padding: theme.spacing(7, 10),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(7, 6.5),
    },
  },
}));

export default match => {
  const classes = useStyles();
  const user = useSelector(getUser);
  const url = match.path;
  const [{ isLoading, result }, fetchUserDiscount] = useAsync(async () => {
    const activePlanDiscount = await functions.httpsCallable('currentStripeCustomer')(user.primaryEmail);
    return activePlanDiscount?.data?.discount?.coupon;
  });
  const isLoadingCurrentCoupon = isLoading && result && (!result.percent_off || !result.amount_off);

  useEffect(() => {
    fetchUserDiscount();
    // eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="lg" className={classes.mainContent}>
      <Typography variant="subtitle1" color="textSecondary">
        Settings
      </Typography>
      <Switch>
        <Route path={`${url}/membership`}>
          <Typography variant="h4">Membership</Typography>
          {!isLoadingCurrentCoupon && userPlanMessage(user, result?.percent_off, result?.amount_off)}
        </Route>
        <Route default>
          <Typography variant="h4">Edit Your Account</Typography>
        </Route>
      </Switch>
      <Divider className={classes.divider} />
      <Switch>
        <Route
          path={`${url}/membership`}
          render={() => (
            <Membership
              currentCoupon={result}
              isLoadingCurrentCoupon={isLoadingCurrentCoupon}
              updateCoupon={fetchUserDiscount}
            />
          )}
        />
        <Route
          default
          render={() => <Account currentCoupon={result} isLoadingCurrentCoupon={isLoadingCurrentCoupon} />}
        />
      </Switch>
    </Container>
  );
};
