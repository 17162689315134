import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { functions } from '../../firebase';
import useAsync from '../../hooks/useAsync';
import { useDispatch } from 'react-redux';
import { setUserPlan } from '../../store/actions/user';
import { createPaymentMethod } from '../../store/services/stripe';
import FormButtons from '../Auth/FormButtons';

const useStyles = makeStyles(theme => ({
  stripeInput: {
    border: '1px solid #CDCDCD',
    borderRadius: 25,
    padding: '21px 20px',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  error: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    fontWeight: 500,
    color: 'red',
  },
  success: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    fontWeight: 500,
    color: 'green',
  },
  buttonContainer: {
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  cancelButton: {
    textAlign: 'end',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
    '& > button': {
      border: '1px solid red !important',
      color: 'red',
    },
  },
}));

const EditCreditCard = ({ setIsCreditCardOpen, isCreditCardOpen }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [isSuccess, setIsSuccess] = useState(false);

  const [{ isLoading, error }, updatePaymentMethod] = useAsync(async event => {
    event.preventDefault();

    const paymentMethod = await createPaymentMethod({ stripe, elements });

    const result = await functions.httpsCallable('updatePaymentMethod')(paymentMethod);

    setIsSuccess(true);
    dispatch(setUserPlan(result && result.data));
  });

  return (
    <form onSubmit={updatePaymentMethod} id="editCreditCardForm">
      <Grid container justify="flex-start">
        <Grid item xs={12} lg={7}>
          <CardElement className={classes.stripeInput} />
          {error && (
            <div id="error" className={classes.error}>
              There was a error with your card
            </div>
          )}
          {isSuccess && (
            <div id="success" className={classes.success}>
              Your information has been updated
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container item lg={7} className={classes.buttonContainer}>
        <Grid item xs={12} lg={6}>
          <FormButtons className={classes.saveButton} isLoading={!stripe || isLoading} primaryText={'Save Changes'} />
        </Grid>
        <Grid item xs={12} lg={6} className={classes.cancelButton}>
          <Button variant="outlined" color="primary" onClick={() => setIsCreditCardOpen(!isCreditCardOpen)}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditCreditCard;
