import React, { useState, useEffect, useRef, useCallback } from 'react';
import { OutlinedInput, InputAdornment, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import EditDialog from './EditDialog';
import { ORDERED_ADDR_KEYS, FULLNAME } from './constants';
import { InputDelete } from '../../images';

import PhoneNumberInput from '../utils/PhoneNumberInput';

const useStyles = makeStyles(theme => ({
  input: {
    marginTop: theme.spacing(1.5),
    color: `${theme.palette.grey[400]} !important`,
    '&:hover': {
      color: 'black !important',
    },
    '& input:focus': {
      color: 'black !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[200],
    },
  },
  deleteButton: {
    '& svg': {
      transitionDuration: 200,
      transitionProperty: 'color',
      fill: theme.palette.grey[300],
      width: theme.spacing(2.25),
      height: theme.spacing(2.25),
    },
    '&:hover svg': {
      fill: 'black',
    },
  },
}));

export default ({
  value,
  onChange,
  onDelete,
  isAddress,
  isPhoneNumber,
  isAlias,
  isAbovePlanLimit,
  isNew,
  setIsOpen,
}) => {
  const classes = useStyles();
  const inputRef = useRef();
  const [isDialogOpen, setDialogOpen] = useState(isNew || false);
  const [inputValue, setInputValue] = useState(value);

  const currentObjectValues = (keys, joinWith = ', ') =>
    keys
      .map(key => value[key])
      .filter(v => !!v)
      .join(joinWith);

  const displayVal = isAddress
    ? currentObjectValues(ORDERED_ADDR_KEYS)
    : isAlias
    ? currentObjectValues(FULLNAME, ' ')
    : inputValue;

  const handleObjectEditing = useCallback(() => {
    if (!isAlias && !isAddress) return;
    setDialogOpen(true);
  }, [isAlias, isAddress, setDialogOpen]);

  useEffect(() => {
    if (isAddress || isAlias) return;
    if (!displayVal) {
      inputRef.current.focus();
      if (!isNew) {
        inputRef.current.blur();
      }
    }
  }, [displayVal, isAddress, isAlias, isNew]);

  return (
    <>
      <OutlinedInput
        variant="outlined"
        inputRef={inputRef}
        className={classes.input}
        fullWidth
        value={displayVal}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            inputRef.current.blur();
          }
        }}
        readOnly={isAddress || isAlias}
        onClick={handleObjectEditing}
        onChange={e => setInputValue(e.target.value)}
        onBlur={() => (!inputValue ? onDelete() : (!isAlias || !isAddress) && onChange(inputValue))}
        // If not a phone number we need to supply the input type to prevent error.
        inputComponent={isPhoneNumber ? PhoneNumberInput : 'input'}
        startAdornment={
          isAbovePlanLimit ? (
            <InputAdornment position="start">
              <Tooltip title={'Upgrade to protect this information'}>
                <WarningIcon color="secondary" />
              </Tooltip>
            </InputAdornment>
          ) : null
        }
        endAdornment={
          <InputAdornment position="end" className={classes.deleteButton}>
            <IconButton
              onClick={e => {
                //This will prevent opening the address modal when deleting a address field
                e.stopPropagation();
                onDelete();
              }}
            >
              <InputDelete />
            </IconButton>
          </InputAdornment>
        }
      />
      {(isAddress || isAlias) && (
        <EditDialog
          value={value}
          open={isDialogOpen}
          onClose={() => setDialogOpen(false) && setIsOpen(false)}
          onConfirm={onChange}
          removeField={onDelete}
          isAlias={isAlias}
        />
      )}
    </>
  );
};
