import React from 'react';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isBillingValid } from './store/selectors/user';
import Billing from './screens/Billing';

const BillableRoute = ({ component: Component, ...rest }) => {
  const billingValid = useSelector(isBillingValid);

  return (
    <>
      {Boolean(billingValid) ? (
        <Route render={props => <Component {...props} {...rest} />} />
      ) : (
        <Route render={props => <Billing {...props} {...rest} />} />
      )}
    </>
  );
};

export default withRouter(BillableRoute);
