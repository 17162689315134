export default color => ({
  borderRadius: '50%',
  backgroundColor: color,
  width: 30,
  height: 30,
  color: 'white',
  // makes sure icon is in the center
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
});
