import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { NavAlerts, NavSettings, NavHandbook } from '../../images';
import { QuestionAnswer } from '@material-ui/icons';

import { BOTTOM_NAV_HEIGHT } from './constants';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    zIndex: 1201, // higher than permanent drawers
    width: '100vw',
    height: BOTTOM_NAV_HEIGHT,
    bottom: 0,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette.navBg,
  },
  navLink: {
    flex: 1,
    display: 'flex',
    alignItems: 'stretch',
    textDecoration: 'none',
    color: theme.palette.link,
    '& svg': {
      fill: theme.palette.link,
    },
    '& .MuiBottomNavigationAction-label': {
      marginTop: theme.spacing(1),
    },
    '&.active': {
      '& .MuiBottomNavigationAction-label': {
        color: theme.palette.primary.main,
      },
      '& svg': {
        fill: theme.palette.secondary.main,
      },
    },
  },
}));

export default ({ trafficType }) => {
  const classes = useStyles();

  // Implememnted our own bottom nav because MaterialUI's bottom-nav for some reason only shows on scroll on mobile.
  return (
    <BottomNavigation className={classes.root}>
      <NavLink to={{ pathname: '/alerts', search: trafficType }} className={classes.navLink} activeClassName="active">
        <BottomNavigationAction showLabel label="Alerts" icon={<NavAlerts />} />
      </NavLink>
      <NavLink to={{ pathname: '/settings', search: trafficType }} className={classes.navLink} activeClassName="active">
        <BottomNavigationAction showLabel label="Settings" icon={<NavSettings />} />
      </NavLink>
      <NavLink to={{ pathname: '/faq', search: trafficType }} className={classes.navLink} activeClassName="active">
        <BottomNavigationAction showLabel label="FAQ" icon={<QuestionAnswer />} />
      </NavLink>
      <a href="/#" className={classes.navLink}>
        <BottomNavigationAction showLabel label="Handbook" icon={<NavHandbook />} />
      </a>
    </BottomNavigation>
  );
};
