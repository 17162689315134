import React from 'react';
import { daysLeftInTrial } from '../store/selectors/user';
import { useSelector } from 'react-redux';
import CommonAlert from './CommonAlert';

export default () => {
  const days = useSelector(daysLeftInTrial);

  const message = days <= 0 ? `Your trial has expired` : `You have ${days} days left on your trial.`;
  const link = '/billing';

  if (!days) {
    return null;
  }
  return <CommonAlert message={message} link={link} />;
};
