import React, { useEffect } from 'react';
import { auth } from '../../firebase';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { Tooltip, Drawer, List, Divider, ListItem, ListItemIcon, ListItemText, useTheme } from '@material-ui/core';
import {
  ArrowBack as CollapseIcon,
  ArrowForward as ExpandIcon,
  PowerSettingsNewRounded as SignOutIcon,
  QuestionAnswer,
} from '@material-ui/icons';
import useWindowSize from '../../hooks/useWindowSize';

import { NavAlerts, NavSettings, NavHandbook, SSLogo } from '../../images';

const DRAWER_WIDTH = 240;

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    backgroundColor: theme.palette.navBg,
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(11) - 1,
  },
  list: {
    '& .MuiListItem-root': {
      margin: `${theme.spacing(2)}px 0`,
      padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    },
  },
  navLink: {
    textDecoration: 'none',
    color: theme.palette.link,
    '& svg': {
      fill: theme.palette.link,
    },
    '&.active': {
      color: theme.palette.primary.main,
      '& svg': {
        fill: theme.palette.secondary.main,
      },
    },
  },
  logo: {
    display: 'block',
    margin: 'auto',
    marginTop: 40,
    marginBottom: 80,
    width: 85,
    height: 85,
  },
}));

export default ({ trafficType }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setDrawerOpen] = React.useState(true);
  const windowSize = useWindowSize();

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  // When window-size changes, automatically reset side-nav openness depending on window width.
  useEffect(() => {
    if (windowSize.width < theme.breakpoints.values['lg']) {
      setDrawerOpen(false);
    } else {
      setDrawerOpen(true);
    }
  }, [windowSize, theme.breakpoints.values]);

  const getNavLink = ({ path, icon, label }) => {
    return (
      <NavLink to={{ pathname: path, search: trafficType }} className={classes.navLink} activeClassName="active">
        <ListItem button>
          <Tooltip title={label}>
            <ListItemIcon>{icon}</ListItemIcon>
          </Tooltip>
          <ListItemText primary={label} />
        </ListItem>
      </NavLink>
    );
  };

  return (
    <Drawer
      className={classnames({
        [classes.drawer]: true,
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      variant="persistent"
      anchor="left"
      open={true} //
      classes={{
        paper: classnames({
          [classes.drawerPaper]: true,
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      {/** Top List */}
      <List className={classes.list}>
        <SSLogo className={classes.logo} />
        {getNavLink({ path: '/alerts', label: 'Alert Vault', icon: <NavAlerts /> })}
        {getNavLink({ path: '/settings', label: 'Settings', icon: <NavSettings /> })}
        {getNavLink({ path: '/faq', label: 'FAQ', icon: <QuestionAnswer /> })}

        <a
          href={`${process.env.REACT_APP_LANDING_SITE_URL}/handbook/privacy-basics`}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.navLink}
        >
          <ListItem button>
            <Tooltip title="Handbook">
              <ListItemIcon>
                <NavHandbook />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Handbook" />
          </ListItem>
        </a>
      </List>

      {/** Bottom List */}
      <List className={classes.list}>
        {/** TODO: Temporary until designer decides where to put the sign-out button */}
        <ListItem button onClick={() => auth().signOut()}>
          <ListItemIcon>
            <SignOutIcon />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItem>
        <Divider />
        <ListItem button onClick={open ? handleDrawerClose : handleDrawerOpen}>
          <ListItemIcon>{open ? <CollapseIcon /> : <ExpandIcon />}</ListItemIcon>
          <ListItemText primary="Collapse" />
        </ListItem>
      </List>
    </Drawer>
  );
};
