import React from 'react';
import { AppBar, Typography, Hidden, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ProgressCircle from './ProgressCircle';
import ProfilePhoto from '../ProtectedInfo/ProfilePhoto';

const useStyles = makeStyles(theme => ({
  stickyDisplay: {
    backgroundColor: theme.palette.navBg,
    height: theme.spacing(8),
    marginTop: -theme.spacing(8), // Counteract its own height, so it doesn't mess with layout when it appears.
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '& svg': {
      // placeholder
      width: 40,
      height: 40,
      margin: '0 12px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  contentContainer: {
    position: 'relative',
    maxWidth: 1100, // Same as alert-screen's main-content max-width
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  profileIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: theme.spacing(10), // align with right-side of alert-screen's main-content
    [theme.breakpoints.down('md')]: {
      maxHeight: theme.spacing(4),
      maxWidth: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(6),
    },
    [theme.breakpoints.down('xs')]: {
      right: theme.spacing(3),
    },
  },
  circleContainer: {
    width: '100px',
    position: 'relative',
    marginBottom: theme.spacing(1),
  },
}));

export default ({ onProfileClick, alertsCount, percentage, alertTypePercentage }) => {
  const classes = useStyles();

  return (
    <AppBar position="sticky" color="inherit" elevation={1} className={classes.stickyDisplay}>
      <Container className={classes.contentContainer}>
        <Hidden smDown>
          <Typography variant="subtitle2">Total Alerts: {alertsCount}</Typography>
        </Hidden>
        <div className={classes.circleContainer}>
          <ProgressCircle isTopBar={true} alertTypePercentage={alertTypePercentage} />
        </div>

        <Typography variant="subtitle2">{percentage}% Protected</Typography>
        <Hidden lgUp>
          <ProfilePhoto className={classes.profileIcon} onClick={onProfileClick} />
        </Hidden>
      </Container>
    </AppBar>
  );
};
