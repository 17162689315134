import React from 'react';
import {
  Dialog,
  IconButton,
  Typography,
  Grid,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
  Link,
} from '@material-ui/core';
import { Close as CloseIcon, Info as InfoIcon } from '@material-ui/icons';
import { useConfirm } from 'material-ui-confirm';
import useAsync from '../../hooks/useAsync';
import { makeStyles } from '@material-ui/core/styles';
import { db } from '../../firebase';
import { format } from 'date-fns';
import mixpanel from 'mixpanel-browser';

import { ALERT_UI } from './constants';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(5, 4, 7, 4),
    borderRadius: theme.spacing(4),
    maxHeight: '75%',
    width: 680,
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 3),
    },
  },
  paperSpacebar: {
    height: '100%',
    width: '20%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 15,
    top: 10,
  },
  iconFather: {
    width: 34,
    height: 34,
    marginRight: theme.spacing(3),
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: theme.spacing(2.8),
    fill: '#eee',
  },
  bold: {
    fontWeight: 500,
  },
  bolder: {
    fontWeight: 600,
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
  button: {
    minWidth: 100,
    height: 48,
    width: 210,
    boxShadow: 'none',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      margin: '0 auto',
    },
  },
  optOutButton: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  smallSpacing: {
    marginTop: 10,
  },
  siteDesc: {
    marginTop: 25,
    width: '75%',
  },
  siteContainerRemover: {
    marginTop: 35,
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  buttonConfirms: {
    marginTop: 35,
    [theme.breakpoints.down('sm')]: {
      '& > button:last-child': {
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
      },
    },
  },
  alertContent: {
    height: '100%',
  },
  alertTitle: {
    paddingRight: theme.spacing(2),
  },
  handbookBtn: {
    paddingTop: theme.spacing(1),
  },
  anchor: {
    textDecoration: 'none',
    color: theme.palette.link,
  },
  handbookContent: {
    whiteSpace: 'pre-line',
  },
  scanDate: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

const useStylesUrlsTable = makeStyles(theme => ({
  tableCell: {
    padding: theme.spacing(1, 2, 1, 0),
  },
  spacer: {
    margin: theme.spacing(2, 0),
  },
  noData: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 6,
    background: '#ececec',
    padding: theme.spacing(1, 0.6),
    '& > svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

const ViewHandbookActionLink = () => {
  const classes = useStyles();

  return (
    <a
      href={`${process.env.REACT_APP_LANDING_SITE_URL}/handbook/privacy-basics`}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.anchor}
    >
      <Typography variant="subtitle1">View the Privacy Handbook.</Typography>
    </a>
  );
};

const UrlsTable = ({ urls }) => {
  const classes = useStylesUrlsTable();

  return urls.length ? (
    <Table className={classes.spacer} size="small">
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCell}>Data Sources</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {urls.map((url, i) => (
          <TableRow key={i}>
            <TableCell className={classes.tableCell}>
              <Link href={url} target="_blank">
                {url}
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <div className={`${classes.spacer} ${classes.noData}`}>
      <InfoIcon /> No links sites to display.
    </div>
  );
};

export default ({ alert, open, onClose, sites, handbooks }) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const site = sites.find(s => s.id === alert.site.id);
  const handbook = handbooks.find(h => h.id === site.handbook);
  const scanDate = format(alert.createdAt, 'MM/dd/yyyy');

  const foundPII = [
    site.supportedPII['aliases'] && `${alert.data.firstName} ${alert.data.lastName}`,
    site.supportedPII['addresses'] && `${alert.data.address}, ${alert.data.city}, ${alert.data.state}`,
    site.supportedPII['emails'] && `${alert.data.email}`,
    site.supportedPII['phoneNumbers'] && `${alert.data.phone}`,
  ].filter(data => data);

  const updateAlertStatus = async (alertObject, type) => {
    if (type === 'manual') {
      mixpanel.track('Hit Milestone', { Event: 'record_removed', 'Meta 1': alertObject.site.id });
    }
    await db
      .collection('alerts')
      .doc(alertObject.id)
      .update({ type });
    onClose();
  };
  // eslint-disable-next-line no-unused-vars
  const [{ _isLoading, _error }, updateNotMe] = useAsync(async alertObject => {
    await confirm({
      description: 'Doing it will remove this alert from your alert vault.',
      confirmationText: 'Confirm',
      cancellationText: 'Go Back',
    });
    updateAlertStatus(alertObject, 'notMe');
  });

  const Icon = ALERT_UI[alert.type]?.icon;

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }} maxWidth="md">
      <div className={classes.paperSpacebar}>
        <div className={classes.iconFather}>{Boolean(Icon) && <Icon className={classes.icon} />}</div>
      </div>
      <div className={classes.alertContent}>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" className={classes.alertTitle}>
          Safe Shepherd has found <br />
          {foundPII.join(', ').replace(/, ([^,]*)$/, ' and $1')} exposed on{' '}
          <span className={classes.bolder}>{alert.site.label}</span>&nbsp;
          {alert.type === 'unsupported' && 'for you to manually remove'}
        </Typography>
        {alert.type !== 'unsupported' && (
          <Typography variant="subtitle2" className={classes.scanDate}>
            Submitted on: {scanDate}
          </Typography>
        )}
        <Typography className={classes.siteDesc} variant="body1" color="textSecondary">
          {handbook && handbook.aboutSite}
        </Typography>
        <UrlsTable urls={alert.result.urls || []} />
        <Grid container spacing={0}>
          <Grid item md={8}>
            <Typography className={classes.bold} variant="h6">
              What To Do?
            </Typography>
            {handbook ? (
              <div className={classes.handbookContent}>
                <ViewHandbookActionLink />
                {handbook.content.replace(/[&/\\#, +()$~%'":*?<>{}]/g, ' ').substring(0, 296)}...{' '}
                <a
                  href={`${process.env.REACT_APP_LANDING_SITE_URL}/handbook/${handbook.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.anchor}
                >
                  Read More
                </a>
              </div>
            ) : (
              <ViewHandbookActionLink />
            )}
          </Grid>
          {handbook && (
            <Hidden smDown>
              <Grid item md={4} className={classes.handbookBtn}>
                <a
                  href={`${process.env.REACT_APP_LANDING_SITE_URL}/handbook/${handbook.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.anchor}
                >
                  <Button className={classes.button} variant="contained" color="secondary">
                    Opt-Out Instructions
                  </Button>
                </a>
              </Grid>
            </Hidden>
          )}
        </Grid>
        <div className={classes.buttonConfirms}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => updateAlertStatus(alert, 'manual')}
          >
            {alert.type === 'unsupported' ? "I've Removed" : 'Opt-Out Confirmed'}
          </Button>
          <Button className={classes.button} variant="contained" onClick={() => updateNotMe(alert)}>
            This is not me
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
