import { CardElement } from '@stripe/react-stripe-js';

export const createPaymentMethod = async options => {
  const { stripe, elements } = options;
  if (!stripe || !elements) {
    return;
  }

  const card = elements.getElement(CardElement);
  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: 'card',
    card,
  });

  if (error) {
    throw new Error(error);
  }

  return paymentMethod;
};
