export default event => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'transaction',
      transactionId: event?.subscriptionId,
      transactionTotal: event?.price,
      transactionProducts: [
        {
          sku: event?.id,
          name: event?.label,
          category: 'Membership Plans',
          price: event?.price,
          quantity: 1,
        },
      ],
    });
  } catch (_) {
    console.log('Problem fetching desired gtm statistics. Data layer not updated.');
  }
};
