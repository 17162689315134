import React from 'react';
import { getUser } from '../store/selectors/user';
import { useSelector } from 'react-redux';
import CommonAlert from './CommonAlert';

export default () => {
  const { isViewing, firstName, lastName } = useSelector(getUser);

  const message = `You are currently viewing the profile of
  ${firstName} ${lastName}`;
  const id = 'viewingAsAlert';

  if (!isViewing) {
    return null;
  }

  return <CommonAlert message={message} id={id} />;
};
