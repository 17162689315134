import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
const breakpoints = createBreakpoints({});

const PRIMARY_MAIN = '#071038';

// Overrides default Material-UI themes that can be found at: https://material-ui.com/customization/default-theme/
export default {
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 800,
      lg: 1366,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: PRIMARY_MAIN,
    },
    secondary: {
      main: '#2CD9DA',
    },
    link: '#b5b5b5',
    navBg: '#fefefe',
  },
  typography: {
    fontFamily: '"DM Sans", sans-serif',
    h3: {
      lineHeight: '1.15em',
      [breakpoints.down('xs')]: {
        fontSize: '2.4rem',
      },
    },
    h6: {
      fontSize: '1.125rem',
    },
    button: {
      textTransform: 'initial',
      whiteSpace: 'nowrap',
    },
  },
  shape: { borderRadius: 12 },

  // If the rule is not injected by the theme, apply the extra styling using `overrides`
  overrides: {
    MuiInputBase: {
      root: {
        color: 'currentColor',
        fontWeight: 500,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 25, // padding + half of text height
      },
      input: {
        padding: ' 22px 20px',
      },
    },
    MuiCard: {
      root: {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow: 'initial',
      },
    },
    MuiButton: {
      root: {
        padding: '12px 24px',
        minWidth: 120,
        '& ~.MuiButton-root': {
          marginLeft: 12,
        },
        [breakpoints.down('xs')]: {
          minWidth: 'initial',
        },
      },
      text: {
        padding: '12px 24px',
      },
      outlined: {
        padding: '11px 23px',
      },
      contained: {
        boxShadow: 'initial',
      },
      containedSecondary: {
        color: 'white',
      },
    },
  },
};
