import React from 'react';
import { Hidden } from '@material-ui/core';
import SideNav from './SideNav';
import BottomNav from './BottomNav';

import { BOTTOM_NAV_BREAKPOINT_DOWN, BOTTOM_NAV_BREAKPOINT_UP } from './constants';

export default ({ trafficType }) => {
  const downStr = `${BOTTOM_NAV_BREAKPOINT_DOWN}Down`;
  const upStr = `${BOTTOM_NAV_BREAKPOINT_UP}Up`;

  return (
    <>
      <Hidden {...{ [downStr]: true }}>
        <SideNav trafficType={trafficType} />
      </Hidden>
      <Hidden {...{ [upStr]: true }}>
        <BottomNav trafficType={trafficType} />
      </Hidden>
    </>
  );
};
