import React, { useState, useEffect, useCallback } from 'react';
import { Typography, TextField, Grid, Divider, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { functions, auth } from '../../firebase';
import FormButtons from './FormButtons';
import { useAsync, useQuery, useReCaptcha } from '../../hooks';

import PhoneNumberInput from '../utils/PhoneNumberInput';
import mixpanel from 'mixpanel-browser';
import googleTagManager from '../utils/googleTagManager';
import DateOfBirthPicker from '../utils/DateOfBirthPicker';

const useStyles = makeStyles(theme => ({
  signUpTitle: {
    margin: `${theme.spacing(3)}px 0`,
    fontSize: '1.5rem',
    fontWeight: 400,
  },
  button: {
    marginTop: theme.spacing(1.5),
  },
}));

const signUp = async info => {
  //Prevent storing confirmPassword to DB
  if (info.confirmPassword) {
    delete info.confirmPassword;
  }
  const { data: token } = await functions.httpsCallable('signUp')(info);
  const result = await auth().signInWithCustomToken(token);
  mixpanel.track('$signup', { user_id: result.user.uid });
  googleTagManager('new_signup');
};

export default () => {
  const classes = useStyles();
  const [info, setInfo] = useState({});
  const [errors, setErrors] = useState({});

  const queryToken = useQuery('t'); // this will be set in the URL if the user got here using an account-update link we sent them
  const [{ isLoading, error }, doSignup] = useAsync(() => signUp({ ...info, queryToken }));

  const setIsCaptchaReady = useCallback(() => {
    setInfo(info => ({ ...info, captchaVerified: true }));
  }, []);

  const [verifyReCaptcha, { isLoading: isLoadingCaptcha }] = useReCaptcha({
    action: 'signUp',
    onVerify: setIsCaptchaReady,
  });

  // pre-fill email field if it's in the url
  const queryEmail = useQuery('e');

  useEffect(() => {
    mixpanel.track('Signup Click');
    if (!!queryEmail) {
      setInfo(info => ({ ...info, email: queryEmail }));
    }
  }, [queryEmail, setInfo]);

  const generateField = useCallback(
    ({ fieldName, label, type = 'text', required = true, inputProps, inputLabelProps = false }) => {
      if (info[fieldName] === undefined) {
        setInfo({ ...info, [fieldName]: '' });
      }

      return (
        <>
          <TextField
            label={label}
            type={type}
            fullWidth
            required={required}
            value={info[fieldName]}
            variant="outlined"
            onChange={e => setInfo({ ...info, [fieldName]: e.target.value })}
            // If not a phone number we need to suply the input type to prevent error.
            InputProps={{
              inputComponent: fieldName === 'phoneNumber' ? PhoneNumberInput : 'input',
            }}
            InputLabelProps={inputLabelProps ? { shrink: true } : null}
            error={!!errors[fieldName]}
            helperText={errors[fieldName]}
            inputProps={inputProps}
          />
        </>
      );
    },
    [errors, info]
  );

  const validate = () => {
    const errors = {};

    if (info['password'] !== info['confirmPassword']) {
      errors.password = 'Passwords do not match';
      errors.confirmPassword = 'Passwords do not match';
    }

    if (info['dob'] > `${validMinYear}-${currentMonthDay}`) {
      errors.dob = 'You must be 18 years or older to signup for Safe Shepherd services';
    }

    if (!info['captchaVerified']) {
      errors.captchaVerified = `Please verify you're human by clicking the button below to continue`;
    }

    // Add other programmatic validations here

    setErrors(errors);
    return !Object.keys(errors).length;
  };

  const isAccountUpdate = !!queryToken;

  const currentDate = new Date().toISOString();
  const validMinYear = currentDate.slice(0, 4) - 18;
  const currentMonthDay = currentDate.slice(5, 10);

  return (
    <form
      className={classes.loginFieldsContainer}
      onSubmit={e => {
        e.preventDefault();

        validate() && doSignup();
      }}
    >
      <Typography variant="h1" className={classes.signUpTitle}>
        {isAccountUpdate ? 'Updating your account information' : 'Sign Up'}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {generateField({
            fieldName: 'email',
            label: 'Email',
            type: 'email',
            inputProps: {
              readOnly: !!queryEmail,
            },
          })}
        </Grid>
        <Grid item xs={12}>
          {generateField({
            fieldName: 'password',
            label: 'New Password',
            type: 'password',
            inputProps: {
              pattern: '(.+){6,}',
              title: 'Password must be at least 6 characters',
            },
          })}
        </Grid>
        <Grid item xs={12}>
          {generateField({ fieldName: 'confirmPassword', label: 'Confirm Password', type: 'password' })}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={4}>
          {generateField({ fieldName: 'firstName', label: 'First Name' })}
        </Grid>
        <Grid item xs={4}>
          {generateField({ fieldName: 'middleName', label: 'Middle Name', required: false })}
        </Grid>
        <Grid item xs={4}>
          {generateField({ fieldName: 'lastName', label: 'Last Name' })}
        </Grid>
        <Grid item xs={12}>
          {generateField({ fieldName: 'street', label: 'Street Address' })}
        </Grid>
        <Grid item xs={3}>
          {generateField({ fieldName: 'apt', label: 'Apt. #', required: false })}
        </Grid>
        <Grid item xs={6}>
          {generateField({ fieldName: 'city', label: 'City' })}
        </Grid>
        <Grid item xs={3}>
          {generateField({
            fieldName: 'state',
            label: 'State',
            inputProps: {
              maxLength: 2,
              pattern: '[a-zA-Z]{2,2}',
              title: 'Please use a 2-letter state abbreviation',
            },
          })}
        </Grid>
        <Grid item xs={4}>
          {generateField({
            fieldName: 'zip',
            label: 'ZIP',
            inputProps: {
              maxLength: 5,
              pattern: '[0-9]{5,5}',
              title: 'Please use a 5-digit ZIP code',
            },
          })}
        </Grid>
        <Grid item xs={8}>
          {generateField({ fieldName: 'phoneNumber', label: 'Phone Number', required: false })}
        </Grid>
        <Grid item xs={12}>
          {generateField({ fieldName: 'employer', label: 'Employer', required: false })}
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <DateOfBirthPicker info={info} setInfo={setInfo} />
        </Grid>
        {(errors?.dob || errors?.captchaVerified) && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="error" align="center">
              {errors.dob || errors?.captchaVerified}
            </Typography>
          </Grid>
        )}

        {error && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="error">
              {error.message}
            </Typography>
          </Grid>
        )}
      </Grid>
      {!info['captchaVerified'] && (
        <Button
          className={classes.button}
          fullWidth
          variant="contained"
          color="secondary"
          disabled={isLoadingCaptcha}
          onClick={verifyReCaptcha}
        >
          {isLoadingCaptcha ? (
            <CircularProgress size={20} />
          ) : (
            <Typography variant="body2">Please verify you are a human</Typography>
          )}
        </Button>
      )}
      <FormButtons
        onVerifyReCaptcha
        isLoading={isLoading}
        primaryText={isAccountUpdate ? 'Update Account Settings' : 'Create New Account'}
        secondaryText={isAccountUpdate ? null : 'I already have an account'}
        secondaryPath="/login"
      />
    </form>
  );
};
