import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Typography, Grid, TextField, IconButton, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getUser, daysLeftInTrial } from '../../store/selectors/user';
import { functions } from '../../firebase';
import useAsync from '../../hooks/useAsync';
import { useConfirm } from 'material-ui-confirm';
import { Redirect, useHistory } from 'react-router-dom';
import { setUserPlan } from '../../store/actions/user';
import { useDispatch } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import EditCreditCard from '../../components/Settings/EditCreditCard';
import { Link } from 'react-router-dom';
import {
  ArrowBackIos as ArrowBackIosIcon,
  Close as CloseIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  CreditCard as CreditCardIcon,
} from '@material-ui/icons';
import PlanPicker from '../../components/Billing/PlanPicker';
import CycleSelector from '../../components/Billing/CycleSelector';
import useFirestoreSubscribe from '../../hooks/useFirestoreSubscribe';
import transactionTracking from '../../components/utils/transactionEventData';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  creditCardButtonContainer: {
    margin: theme.spacing(4),
    '& > h5, & > h6': {
      marginBottom: theme.spacing(3),
      display: 'flex',
      '& > svg': {
        marginRight: theme.spacing(0.5),
      },
    },
  },
  error: {
    marginTop: theme.spacing(2),
  },
  linkAllSettings: {
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.link,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
    '& > svg': {
      fontSize: theme.spacing(1.5),
      marginRight: theme.spacing(0.5),
    },
  },
  linkCancel: {
    alignItems: 'left',
    textDecoration: 'none',
    color: theme.palette.link,
    marginBottom: theme.spacing(2),
  },
  couponCode: {
    marginBottom: theme.spacing(3),
  },
  promo: {
    margin: theme.spacing(1, 3),
  },
  removeBtn: {
    padding: 0,
    '& > span > svg': {
      margin: theme.spacing(0.5, 2),
      fontSize: '1rem',
    },
  },
}));

const AddCouponField = ({ classes, coupon, setCoupon, setOpen, setInvalidCode }) => {
  return (
    <TextField
      className={classes.couponCode}
      variant="outlined"
      label="Coupon Code"
      onChange={e => {
        setInvalidCode(false);
        setCoupon({ ...coupon, name: e.target.value });
        setOpen(true);
      }}
    />
  );
};

export default ({ currentCoupon, isLoadingCurrentCoupon, updateCoupon }) => {
  const classes = useStyles();
  const history = useHistory();
  const daysOnTrial = useSelector(daysLeftInTrial);
  const dispatch = useDispatch();
  const confirm = useConfirm();

  if (daysOnTrial > 0) {
    return <Redirect to="/billing" />;
  }

  const user = useSelector(getUser);
  const { plan: currentPlan, card, primaryEmail } = user;
  const trafficType = user?.isAdmin ? '?internal=true' : '';

  const [isCreditCardOpen, setIsCreditCardOpen] = useState(false);
  const [selectedCycle, setSelectedCycle] = useState(currentPlan.cycle);
  const [open, setOpen] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [percentOff, setPercentOff] = useState(0);
  const [amountOff, setAmountOff] = useState(0);
  const [couponDuration, setCouponDuration] = useState('');
  const [durationInMonths, setDurationInMonths] = useState(false);
  const [couponValidated, setCouponValidated] = useState(Boolean(currentCoupon?.name));
  const [isPlanChangeComplete, setIsPlanChangeComplete] = useState(false);
  const [coupon, setCoupon] = useState({});
  const [plans, isLoadingPlans] = useFirestoreSubscribe('plans');
  const [sites, isSitesLoading] = useFirestoreSubscribe('sites');
  const [{ isLoading, error }, doChangePlan] = useAsync(async plan => {
    await confirm({
      description: `I understand that by ${
        parseFloat(plan.price) > parseFloat(currentPlan.price) ? 'upgrading' : 'downgrading'
      } my subscription, my current plan will end today and my new plan will begin.`,
      confirmationText: 'Confirm',
      cancellationText: 'Go Back',
    });
    const result = await functions.httpsCallable('changeSubscriptionPlan')({
      planId: plan.id,
      email: primaryEmail,
      coupon: coupon?.id,
    });
    updateCoupon();
    resetDiscountPreview();
    dispatch(setUserPlan(result.data));
    transactionTracking(result.data);
    setIsPlanChangeComplete(true);
  });
  const [{ isLoading: isLoadingCoupons, error: couponError }, applyCoupon] = useAsync(async () => {
    const activeCoupon = await functions.httpsCallable('fetchActiveCoupons')(coupon.name);
    if (activeCoupon?.data) {
      setCoupon(activeCoupon.data);
      const { percent_off, amount_off, duration, duration_in_months } = activeCoupon.data;
      setPercentOff(percent_off || 0);
      setAmountOff(amount_off || 0);
      setDurationInMonths(duration_in_months >= 12);
      setCouponDuration(duration);
      setCouponValidated(true);
    } else {
      setInvalidCode(true);
    }
  });

  const resetDiscountPreview = () => {
    setPercentOff(0);
    setAmountOff(0);
    setCoupon({});
    setCouponValidated(false);
  };
  const isCouponApplied = !isLoadingCoupons && !isLoadingCurrentCoupon;
  const isLoadingPlansAndDiscounts =
    isLoadingPlans || isLoadingCurrentCoupon || (currentCoupon && !currentCoupon?.name) || isSitesLoading;

  useEffect(() => {
    if (currentCoupon?.name) {
      setCoupon({ ...coupon, ...(currentCoupon?.name && { name: currentCoupon?.name }) });
      setCouponValidated(true);
    }
    // eslint-disable-next-line
  }, [currentCoupon?.name]);

  useEffect(() => {
    history.push({ search: trafficType });
    // eslint-disable-next-line
  }, []);

  if (isLoadingPlansAndDiscounts) {
    return <CircularProgress />;
  }

  return (
    <div className={classes.root}>
      <Link to={{ pathname: '/settings', search: trafficType }} className={classes.linkAllSettings}>
        <ArrowBackIosIcon />
        Back to all settings
      </Link>

      <CycleSelector setSelectedCycle={setSelectedCycle} selectedCycle={selectedCycle} />

      <Grid container spacing={5}>
        <PlanPicker
          selectedCycle={selectedCycle}
          selectedType={currentPlan.type}
          plans={plans.filter(plan => plan.cycle === selectedCycle).sort((a, b) => a.price - b.price)}
          sites={sites}
          currentPlan={currentPlan}
          doChangePlan={doChangePlan}
          isLoading={isLoading}
          percentOff={percentOff}
          amountOff={amountOff}
          couponDuration={couponDuration}
          durationInMonths={durationInMonths}
        />
      </Grid>
      {error && (
        <Typography className={classes.error} variant="subtitle2" color="error">
          {error.message}
        </Typography>
      )}
      <div className={classes.creditCardButtonContainer}>
        <Typography variant="h5" color="textSecondary">
          Apply Coupon
        </Typography>
        {isCouponApplied && couponValidated ? (
          <>
            <Typography variant="subtitle1" color="secondary">
              <ConfirmationNumberIcon color="secondary" />
              {coupon?.name || currentCoupon?.name}
              {!currentCoupon?.name && !isPlanChangeComplete && (
                <IconButton
                  className={classes.removeBtn}
                  aria-label="remove"
                  onClick={() => {
                    resetDiscountPreview();
                  }}
                  style={{ backgroundColor: 'transparent' }}
                >
                  <CloseIcon className={classes.removeCoupon} />
                </IconButton>
              )}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              *Promo codes will not be applied to your plan until your transaction is confirmed
            </Typography>
          </>
        ) : (
          <AddCouponField
            classes={classes}
            coupon={coupon}
            setCoupon={setCoupon}
            setOpen={setOpen}
            setInvalidCode={setInvalidCode}
          />
        )}
        {(open || isLoadingCoupons) && (
          <Button
            variant="outlined"
            disabled={isLoadingCoupons}
            color="primary"
            className={classes.promo}
            onClick={() => {
              applyCoupon();
              setIsPlanChangeComplete(false);
              setOpen(false);
            }}
          >
            {isLoadingCoupons ? <CircularProgress size={20} /> : 'Apply Coupon'}
          </Button>
        )}
        {(couponError || invalidCode) && (
          <Typography className={classes.error} variant="subtitle2" color="error">
            Unable to apply coupon or coupon code is invalid
          </Typography>
        )}
        <Typography variant="h5" color="textSecondary">
          Billing Details
        </Typography>
        {card && (
          <Typography variant="subtitle1" color="textSecondary">
            <CreditCardIcon /> {`${card.brand[0].toUpperCase()}${card.brand.slice(1)}`} ending in {card.number}
          </Typography>
        )}
        {!isCreditCardOpen && (
          <Button
            variant="outlined"
            color="primary"
            id="editCreditCard"
            onClick={() => setIsCreditCardOpen(!isCreditCardOpen)}
          >
            Update Card
          </Button>
        )}
      </div>

      {isCreditCardOpen && (
        <>
          <Elements stripe={stripePromise}>
            <EditCreditCard setIsCreditCardOpen={setIsCreditCardOpen} isCreditCardOpen={isCreditCardOpen} />
          </Elements>
        </>
      )}

      <div>
        <Link to="/faq#how-do-i-cancel-my-account" className={classes.linkCancel}>
          Cancel my account
        </Link>
      </div>
    </div>
  );
};
