import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import classNames from 'classnames';
import { ALERT_TYPES, ALERT_UI } from './constants';
import { InputDropdown } from '../../images';

const useStyles = makeStyles(theme => {
  const ITEMS_SIDE_PADDING = theme.spacing(4);

  return {
    selectContainer: {
      width: 'max-content',
      minWidth: 235, // estimated width of the dropdown menu
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.grey[200],
      },
      '& .MuiOutlinedInput-root': {
        boxShadow: '0px 15px 20px rgba(0,0,0,0.05)',
        background: 'white',
      },
    },
    select: {
      padding: `${theme.spacing(3)}px ${theme.spacing(7)}px ${theme.spacing(3)}px ${ITEMS_SIDE_PADDING}px !important`,
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.875em !important',
      fontWeight: '500',
      height: '1rem',
      '&:focus': {
        background: 'none',
      },
    },
    dropdownMenuPaper: {
      boxShadow: '0px 15px 20px rgba(0,0,0,0.05)', // TODO: DRY this boxShadow value
      borderRadius: theme.spacing(3),
      border: `1px solid ${theme.palette.grey[200]}`,
    },
    dropdownMenuList: {
      padding: 0,
      '& .MuiListItem-root': {
        padding: `${theme.spacing(3)}px ${ITEMS_SIDE_PADDING}px`,
        fontSize: '0.875em !important',
        fontWeight: '500',
        '&:not(:last-child)': {
          borderBottom: `1px solid ${theme.palette.grey[200]}`,
        },
      },
    },
    dropdownIcon: {
      marginRight: theme.spacing(1),
    },
    selectDropdownIcon: {
      position: 'absolute',
      height: '0.75rem',
      right: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      selectContainer: {
        width: '100%',
      },
    },
  };
});

const iconStyles = {
  selectDropdownIcon: {
    position: 'absolute',
    height: '0.75rem',
    right: '24px',
    top: 'auto',
  },
};

const InputDropdownCustomStyles = withStyles(iconStyles)(({ className, classes, ...rest }) => {
  return <InputDropdown {...rest} className={classNames(className, classes.selectDropdownIcon)} />;
});

export default ({ selected, onSelect, alerts }) => {
  const classes = useStyles();
  const getCountOfType = type =>
    alerts.filter(alert => {
      if (type === ALERT_TYPES.REMOVED) {
        return [ALERT_TYPES.MANUAL, ALERT_TYPES.REMOVED, ALERT_TYPES.ADMIN_OPT_OUT, ALERT_TYPES.REQUESTED].includes(
          alert.type
        );
      }
      return alert.type === type;
    }).length;

  return (
    <FormControl variant="outlined" className={classes.selectContainer}>
      <Select
        value={selected}
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          getContentAnchorEl: null,
          classes: {
            paper: classes.dropdownMenuPaper,
            list: classes.dropdownMenuList,
          },
        }}
        classes={{
          select: classes.select,
        }}
        onChange={e => onSelect(e.target.value)}
        IconComponent={InputDropdownCustomStyles}
      >
        <MenuItem value="all">All Alerts ({alerts.length})</MenuItem>
        {Object.values([ALERT_TYPES.REMOVED, ALERT_TYPES.UNSUPPORTED]).map(type => {
          const { label, icon: Icon } = ALERT_UI[type];
          return (
            <MenuItem value={type} key={type}>
              <Icon className={classes.dropdownIcon} />
              {label} ({getCountOfType(type)})
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
