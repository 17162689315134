import { eventChannel } from 'redux-saga';
import { take, put, fork, select, takeLatest, delay } from 'redux-saga/effects';
import { auth, db } from '../../firebase';
import { setUser, setViewingUser, SET_USER_PII, GET_VIEWING_USER } from '../actions/user';

let unsubCurrentUserProfile;

function authChangeChannel() {
  return eventChannel(emit => {
    return auth().onAuthStateChanged(async user => {
      if (!!user) {
        unsubCurrentUserProfile = db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(doc => {
            emit({ ...user, ...doc.data() });
          });
      } else {
        if (unsubCurrentUserProfile) {
          unsubCurrentUserProfile();
        }
        emit(false);
      }
    });
  });
}

function* getViewingUser(action) {
  const doc = yield db
    .collection('users')
    .doc(action.uid)
    .get();

  yield put(setViewingUser({ ...doc.data(), uid: doc.id }));
}

function* saveUserPII() {
  yield delay(400); // debounce

  const {
    user: { uid, PII },
  } = yield select();

  db.collection('users')
    .doc(uid)
    .update({ PII });
}

function* subscribeToAuthChange() {
  const channel = authChangeChannel();

  while (true) {
    const user = yield take(channel);
    yield put(setUser(user));
  }
}

export default function* userSaga() {
  yield fork(subscribeToAuthChange);
  yield takeLatest(SET_USER_PII, saveUserPII);
  yield takeLatest(GET_VIEWING_USER, getViewingUser);
}
