import { auth, db } from '../../../firebase';

const authenticate = async currentPassword => {
  const user = auth().currentUser;
  const cred = auth.EmailAuthProvider.credential(user.email, currentPassword);
  return user.reauthenticateWithCredential(cred);
};

export default async (
  firstName,
  middleName,
  lastName,
  { email, newPassword, currentPassword },
  uid,
  setIncorrectPassword
) => {
  if (Boolean(newPassword) && newPassword.length) {
    try {
      await authenticate(currentPassword);
      setIncorrectPassword('');
    } catch (error) {
      return setIncorrectPassword({ message: 'Incorrect current password' });
    }
  }

  if (firstName || middleName || lastName) {
    await changeUserName(firstName, middleName, lastName, uid);
  }

  if (newPassword) {
    await changeUserPassword(newPassword);
  }

  if (email) {
    await changeUserEmail(email, uid);
  }

  return true;
};

const changeUserName = async (firstName, middleName, lastName, uid) => {
  await db
    .collection('users')
    .doc(uid)
    .update({ firstName, middleName, lastName });
};

const changeUserPassword = async newPassword => {
  await auth().currentUser.updatePassword(newPassword);
};

const changeUserEmail = async (email, uid) => {
  const user = auth().currentUser;
  await user.updateEmail(email);
  await db
    .collection('users')
    .doc(uid)
    .update({ primaryEmail: email });
};
