export const SET_USER = 'SET_USER';
export const SET_USER_PII = 'SET_USER_PII';
export const SET_USER_PLAN = 'SET_USER_PLAN';
export const GET_VIEWING_USER = 'GET_VIEWING_USER';
export const SET_VIEWING_USER = 'SET_VIEWING_USER';

export const setUser = user => ({
  type: SET_USER,
  user,
});

export const getViewingUser = uid => ({
  type: GET_VIEWING_USER,
  uid,
});

export const setViewingUser = user => ({
  type: SET_VIEWING_USER,
  user,
});

export const setUserPII = (key, val) => ({
  type: SET_USER_PII,
  key,
  val,
});

export const setUserPlan = plan => ({
  type: SET_USER_PLAN,
  plan,
});
