import React from 'react';
import { Link } from 'react-router-dom';
import { Button, CircularProgress, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  buttonsContainer: {
    marginTop: theme.spacing(1.5),
    '& > div': {
      marginTop: theme.spacing(1),
      '& a': {
        color: theme.palette.link,
        textDecoration: 'none',
        fontSize: '.82rem',
        fontWeight: 500,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  button: {
    display: 'block',
    width: '100%',
  },
  link: {
    textAlign: 'center',
  },
}));

export default ({ isLoading, primaryText, secondaryText, secondaryPath, secondaryAction, secondaryId }) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonsContainer}>
      <Button className={classes.button} variant="contained" type="submit" color="secondary" disabled={isLoading}>
        {isLoading ? <CircularProgress size={20} /> : <Typography variant="body2">{primaryText}</Typography>}
      </Button>
      <Grid item xs={12} className={classes.link}>
        {!!secondaryPath ? (
          <Link to={secondaryPath} className={classes.textSecondaryStyle}>
            {secondaryText}
          </Link>
        ) : (
          secondaryText && (
            <a onClick={secondaryAction} href="#action" id={secondaryId} className={classes.textSecondaryStyle}>
              {secondaryText}
            </a>
          )
        )}
      </Grid>
    </div>
  );
};
