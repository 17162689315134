export const formatPrice = price => {
  return price.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const priceAfterCoupon = (price, amountOff, percentOff) => {
  let total = Number(price);
  if (amountOff) {
    total = total - Number(amountOff) / 100;
  }
  if (percentOff) {
    total = total * ((100 - percentOff) / 100);
  }

  total = total.toFixed(2).toString();
  return formatPrice(total);
};
