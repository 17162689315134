import React from 'react';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import useAsync from '../../hooks/useAsync';
import { db, storage } from '../../firebase';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
}));

const uploadProfilePhoto = async ({ uid, file }) => {
  const extension = file.type.split('/')[1];
  const fileRef = storage.ref().child(`/users/${uid}/profilePhoto.${extension}`);

  const snapshot = await fileRef.put(file);
  const url = await snapshot.ref.getDownloadURL();
  await db
    .collection('users')
    .doc(uid)
    .update({ profilePhoto: url });
};

export default () => {
  const classes = useStyles();
  const { uid } = useSelector(({ user }) => user);
  const [{ isLoading, error }, doUpload] = useAsync(file => uploadProfilePhoto({ uid, file }));
  return (
    <>
      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        type="file"
        onChange={e => e.target.files.length && doUpload(e.target.files[0])}
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" component="span" disabled={isLoading}>
          {isLoading ? <CircularProgress size={21} /> : 'Update profile picture'}
        </Button>

        {!!error && (
          <Typography variant="subtitle2" color="error">
            {error.message}
          </Typography>
        )}
      </label>
    </>
  );
};
