import { useState, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useReCaptcha = ({ action, onVerify }) => {
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha || !action) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    setError(null);
    setIsloading(true);

    try {
      const token = await executeRecaptcha(action);

      onVerify && onVerify(token);
    } catch (error) {
      setError(error);
    }

    setIsloading(false);
  }, [executeRecaptcha, action, onVerify]);

  return [handleReCaptchaVerify, { isLoading, error }];
};

export default useReCaptcha;
