import React from 'react';
import { daysLeftInSubscription } from '../store/selectors/user';
import { useSelector } from 'react-redux';
import CommonAlert from './CommonAlert';

export default () => {
  const days = useSelector(daysLeftInSubscription);

  const message = `Your subscription is expiring in ${days} days. Stay protected!`;
  const link = '/settings/membership';
  const id = 'subscriptionAlert';

  if (!days || days < 0) {
    return null;
  }

  return <CommonAlert message={message} link={link} id={id} />;
};
