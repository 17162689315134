import React, { useState, useEffect } from 'react';
import { makeStyles, Dialog, TextField, Button } from '@material-ui/core';
import { ORDERED_ADDR_KEYS, FULLNAME } from './constants';

const useStyles = makeStyles(theme => ({
  dialog: {
    padding: theme.spacing(6, 4),
  },
  inputsContainer: {
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default ({ open, value, onClose, onConfirm, removeField, isAlias }) => {
  const [tempValues, setTempValues] = useState(value);
  const classes = useStyles();

  useEffect(() => {
    const deleteEmptyField = event => {
      event.preventDefault();
      if (!value.street && !value.firstName) {
        event.returnValue = '';
        removeField();
      }
    };

    if (open) {
      window.addEventListener('beforeunload', deleteEmptyField);
      return () => window.removeEventListener('beforeunload', deleteEmptyField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      onClose={!value.street && !value.firstName ? removeField : onClose()}
    >
      <form
        onSubmit={e => {
          e.preventDefault();
          onConfirm(tempValues);
          onClose();
        }}
      >
        <div className={classes.inputsContainer}>
          {(isAlias ? FULLNAME : ORDERED_ADDR_KEYS).map(subKey => {
            const subVal = tempValues[subKey];
            return (
              <TextField
                required={subKey !== 'apt' && subKey !== 'middleName'}
                key={subKey}
                className={classes.input}
                fullWidth
                label={
                  isAlias
                    ? { firstName: 'first name', middleName: 'middle name', lastName: 'last name' }[subKey]
                    : subKey
                }
                variant="outlined"
                value={subVal}
                onChange={e => {
                  setTempValues({ ...tempValues, [subKey]: e.target.value });
                }}
              />
            );
          })}
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            onClick={() => {
              if (!value.street && !value.firstName) {
                removeField();
              }
              return onClose();
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Confirm
          </Button>
        </div>
      </form>
    </Dialog>
  );
};
