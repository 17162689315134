import React, { useState, useEffect } from 'react';
import { YearPicker, DayPicker, MonthPicker } from 'react-dropdown-date';
import { makeStyles, Grid, InputLabel } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  datePicker: {
    '& > div': {
      '& > .MuiInputLabel-root': {
        fontSize: '0.8rem',
      },
      '& > select': {
        fontSize: '1rem',
        background: 'transparent',
        textIndent: theme.spacing(0.5),
        lineHeight: 1,
        border: '1px solid rgba(0, 0, 0, 0.3)',
        borderRadius: theme.spacing(2.25),
        '-webkit-appearance': 'none',
        width: '95%',
        height: theme.spacing(6),
      },
    },
  },
}));

const currentYear = new Date().getFullYear();
const validMinYear = currentYear - 18;

export default ({ info, setInfo }) => {
  const classes = useStyles();
  const [date, setDate] = useState({
    month: '',
    day: '',
    year: '',
  });

  useEffect(() => {
    if (date.day && date.month && date.year) {
      setInfo({ ...info, dob: `${date.year}-${date.month}-${date.day}` });
    }
    // eslint-disable-next-line
  }, [date]);

  return (
    <>
      <Grid container className={classes.datePicker} spacing={2}>
        <Grid item xs={12}>
          <InputLabel required>Date of Birth</InputLabel>
        </Grid>
        <Grid item xs={4}>
          <MonthPicker
            defaultValue={'Month'}
            endYearGiven
            year={date.year}
            required={true}
            value={date.month}
            onChange={month => {
              setDate({ ...date, month });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <DayPicker
            defaultValue={'Day'}
            year={date.year}
            month={date.month}
            endYearGiven
            required={true}
            value={date.day}
            onChange={day => {
              setDate({ ...date, day });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <YearPicker
            defaultValue={'Year'}
            start={currentYear - 100}
            end={validMinYear}
            reverse
            required={true}
            value={date.year}
            onChange={year => {
              setDate({ ...date, year });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
