import React from 'react';
import { Typography } from '@material-ui/core';
import { priceAfterCoupon } from '../../../components/utils/priceAfterCoupon';

export default (user, percentOff, amountOff) => {
  const adjustedPrice = priceAfterCoupon(user.plan.price, amountOff, percentOff);
  return adjustedPrice && user.plan.type === 'trial' ? (
    <Typography variant="body1">
      You're being protected by the <b>Premium</b> Free Trial.
    </Typography>
  ) : (
    <Typography variant="body1">
      You're being protected by the <b>{user.plan.label}</b> plan for ${adjustedPrice}
      {
        {
          monthly: ' a month.',
          annual: ' a year.',
          biennial: ' every 2 years',
        }[user.plan.cycle]
      }
    </Typography>
  );
};
