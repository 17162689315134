import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, TextField, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/selectors/user';
import useAsync from '../../../hooks/useAsync';
import saveEditedInfo from '../utils/saveEditedInfo';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'inline-block',
    '& > button': {
      fontWeight: 600,
    },
  },
}));

const useStylesChangePasswordButton = makeStyles(theme => ({
  changePasswordButton: {
    padding: theme.spacing(1.5),
    display: 'block',
    marginBottom: theme.spacing(1.5),
  },
}));

const ChangePasswordButton = ({
  currentPassword,
  newPassword,
  setNewPassword,
  confirmPassword,
  setCurrentPassword,
  setConfirmPassword,
  setPasswordMismatch,
  setEditPassword,
  editPassword,
  passwordMismatch,
}) => {
  const classes = useStylesChangePasswordButton();

  const handleConfirmPasswordCheck = () => {
    setPasswordMismatch({
      passwordMismatch: !!(newPassword && newPassword !== confirmPassword) ? 'Passwords must match' : '',
    });
  };

  return (
    <>
      {editPassword ? (
        <>
          <TextField
            onChange={e => setCurrentPassword(e.target.value)}
            label="Current Password"
            value={currentPassword}
            variant="outlined"
            type="password"
          />
          <TextField
            onChange={e => setNewPassword(e.target.value)}
            label="New Password"
            value={newPassword}
            variant="outlined"
            type="password"
          />
          <TextField
            onChange={e => setConfirmPassword(e.target.value)}
            label="Confirm Password"
            value={confirmPassword}
            variant="outlined"
            onBlur={handleConfirmPasswordCheck}
            type="password"
          />
          {!!passwordMismatch && (
            <Typography variant="body1" color="error">
              {passwordMismatch.passwordMismatch}
            </Typography>
          )}
        </>
      ) : (
        <Button className={classes.changePasswordButton} variant="contained" onClick={() => setEditPassword(true)}>
          Change Password?
        </Button>
      )}
    </>
  );
};

const AccountEditForm = props => {
  const classes = useStyles();

  const user = useSelector(getUser);

  const [firstName, setFirstName] = useState(user.firstName);
  const [middleName, setMiddleName] = useState(user.middleName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [incorrectPassword, setIncorrectPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMismatch, setPasswordMismatch] = useState('');
  const [editPassword, setEditPassword] = useState(false);

  const data = { currentPassword };

  const [{ result, isLoading, error }, handleSave] = useAsync(() =>
    saveEditedInfo(firstName, middleName, lastName, data, user.uid, setIncorrectPassword)
  );

  useEffect(() => {
    setCurrentPassword('');
  }, [result]);

  const buildSaveData = () => {
    if (email && email !== user.email) {
      data.email = email;
    }
    if (newPassword) {
      data.newPassword = newPassword;
    }

    return data;
  };

  //When cancel is press it will reset
  const resetState = () => {
    setPasswordMismatch('');
    setCurrentPassword('');
    setIncorrectPassword('');
  };

  const isSaveDisabled = () => {
    return (
      (newPassword && newPassword !== confirmPassword) ||
      (!newPassword &&
        firstName === user.firstName &&
        middleName === user.middleName &&
        lastName === user.lastName &&
        email === user.email)
    );
  };

  const fields = [
    { label: 'First Name', onChange: e => setFirstName(e.target.value), value: firstName },
    { label: 'Middle Name', onChange: e => setMiddleName(e.target.value), value: middleName },
    { label: 'Last Name', onChange: e => setLastName(e.target.value), value: lastName },
    { label: 'Email', onChange: e => setEmail(e.target.value), value: email },
  ];

  const ActionsButtons = () => {
    return (
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          //Prevents onBlur from executing first than onClick
          onMouseDown={e => e.preventDefault()}
          onClick={() => {
            props.onComplete();
            resetState();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isSaveDisabled()}
          variant="contained"
          color="primary"
          //Prevents onBlur from executing first than onClick
          onMouseDown={e => e.preventDefault()}
          onClick={() => {
            handleSave(buildSaveData());
          }}
        >
          Save
        </Button>
      </div>
    );
  };

  return (
    <>
      {fields.map(field => {
        return (
          <TextField
            onChange={field.onChange}
            label={field.label}
            value={field.value || ''}
            variant="outlined"
            type={field.type}
            key={field.label}
          />
        );
      })}
      <ChangePasswordButton
        currentPassword={currentPassword}
        setCurrentPassword={setCurrentPassword}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        setPasswordMismatch={setPasswordMismatch}
        setEditPassword={setEditPassword}
        editPassword={editPassword}
        passwordMismatch={passwordMismatch}
      />
      {(error || incorrectPassword) && (
        <Typography variant="body1" color="error">
          {error ? error.message : incorrectPassword.message}
        </Typography>
      )}
      <ActionsButtons />
      {isLoading && <CircularProgress size={21} />}
      {result === true && (
        <Typography variant="body1" color="secondary">
          Your information has been updated
        </Typography>
      )}
    </>
  );
};

export default AccountEditForm;
