import React from 'react';
import { ButtonGroup, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CYCLES } from './billingConstants';

const useStyles = makeStyles(theme => ({
  billingButtonContainer: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
    paddingBottom: theme.spacing(3),
  },
}));

export default ({ selectedCycle, setSelectedCycle }) => {
  const classes = useStyles();
  return (
    <div className={classes.billingButtonContainer}>
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button
          variant={selectedCycle === CYCLES.MONTHLY ? 'contained' : 'outlined'}
          color={selectedCycle === CYCLES.MONTHLY ? 'secondary' : 'default'}
          id="changeToMonthly"
          onClick={() => setSelectedCycle(CYCLES.MONTHLY)}
        >
          Monthly
        </Button>
        <Button
          variant={selectedCycle === CYCLES.ANNUAL ? 'contained' : 'outlined'}
          color={selectedCycle === CYCLES.ANNUAL ? 'secondary' : 'default'}
          id="changeToAnnual"
          onClick={() => setSelectedCycle(CYCLES.ANNUAL)}
        >
          Annual
        </Button>
        <Button
          variant={selectedCycle === CYCLES.BIENNIAL ? 'contained' : 'outlined'}
          color={selectedCycle === CYCLES.BIENNIAL ? 'secondary' : 'default'}
          id="changeToAnnual"
          onClick={() => setSelectedCycle(CYCLES.BIENNIAL)}
        >
          2-year
        </Button>
      </ButtonGroup>
    </div>
  );
};
