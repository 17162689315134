import React, { useState } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { ConfirmProvider } from 'material-ui-confirm';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';

import Nav from './components/Nav';
import TopNav from './components/TopNav';
import TrialAlert from './components/TrialAlert';
import SubscriptionEndingAlert from './components/SubscriptionEndingAlert';
import ViewingAsAlert from './components/ViewingAsAlert';
import screens from './screens';
import AuthScreen from './screens/Auth';
import BillingScreen from './screens/Billing';
import BillableRoute from './BillableRoute';
import MixpanelPageView from './MixpanelPageView';

import { BOTTOM_NAV_HEIGHT, BOTTOM_NAV_BREAKPOINT_DOWN } from './components/Nav/constants';

import { isLoggedIn, getUser } from './store/selectors/user';
import { getViewingUser } from './store/actions/user';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.grey[50],

    // Make sure site is at least 100vh so footer's always at the bottom.
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100vh',
  },
  mainContent: {
    flexGrow: 1,
    // add bottom spacing so that contents aren't covered by bottom nav
    [theme.breakpoints.down(BOTTOM_NAV_BREAKPOINT_DOWN)]: {
      marginBottom: BOTTOM_NAV_HEIGHT,
    },
  },
}));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const App = () => {
  const location = useLocation();
  const classes = useStyles();
  const query = useQuery();
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(isLoggedIn); // this will be `undefined` while loading
  const user = useSelector(getUser);
  const [viewingUserId, setViewingUserId] = useState(null);
  const trafficType = user?.isAdmin ? '?internal=true' : '';

  if (Boolean(isUserLoggedIn)) {
    if (query.get('as')) {
      dispatch(getViewingUser(query.get('as')));
    } else {
      if (Boolean(viewingUserId)) {
        dispatch(getViewingUser(viewingUserId));
        // Once viewing user is found set the viewingUserId to null so that
        // when admin tries to sign-in after signing out or a non-admin
        // tries to sign-in in the same browser they are not shown the
        // viewing user.
        setViewingUserId(null);
      }
    }
  } else {
    if (query.get('as') && !viewingUserId) {
      // This handles the case when an admin user tries to view another user
      // and is not already logged into the dashboard. Preserving the
      // viewingUserId here lets us reuse it in the clause above.
      setViewingUserId(query.get('as'));
    }
  }

  const isSignup = location.pathname === '/signup';

  return (
    <>
      {isUserLoggedIn === false && <TopNav />}
      <div className={classes.root}>
        <Helmet>
          {process.env.REACT_APP_ENVIRONMENT !== 'production' && <meta name="robots" content="noindex" />}
          {isSignup && <title>Become a Member | Safe Shepherd</title>}
          {isSignup && (
            <meta
              name="description"
              content="Join over 200,000 satisfied users who have protected their personal information on Safe Shepherd."
            />
          )}
        </Helmet>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RE_CAPTCHA_KEY}>
          <ConfirmProvider>
            {isUserLoggedIn === false && (
              <Switch>
                <Route path="/login" component={AuthScreen} />
                <Route path="/signup" component={AuthScreen} />
                <Route path="/reset" component={AuthScreen} />
                <Route path="/pending-account-update" component={AuthScreen} />
                <Route render={() => <Redirect to="/login" />} />
              </Switch>
            )}

            {/** The logged-in App frame */}
            {Boolean(isUserLoggedIn) && (
              <>
                <Nav trafficType={trafficType} />
                <div
                  className={classnames({
                    [classes.mainContent]: true,
                  })}
                >
                  {location.pathname !== '/alerts' && (
                    <>
                      <TrialAlert />
                      <SubscriptionEndingAlert />
                      <ViewingAsAlert />
                    </>
                  )}
                  <Switch>
                    <Route path="/billing" location={{ search: trafficType }} component={BillingScreen} />
                    {Object.keys(screens).map(path => (
                      <BillableRoute path={path} component={screens[path]} key={path} />
                    ))}
                    <Route default>
                      <Redirect to={{ pathname: '/alerts', search: trafficType }} />
                    </Route>
                  </Switch>
                </div>
              </>
            )}
            <MixpanelPageView />
          </ConfirmProvider>
        </GoogleReCaptchaProvider>
      </div>
    </>
  );
};

export default App;
