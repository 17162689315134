import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, Typography, Hidden, makeStyles } from '@material-ui/core';
import ProgressCircle from './ProgressCircle';

const useStyles = makeStyles(theme => ({
  protectInfoCard: {
    padding: theme.spacing(3),
    display: 'flex',
    '& > *:first-child': {
      flex: 2,
    },
    '& > *:last-child': {
      flex: 1,
    },
  },
  protectInfoProgress: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleContainer: {
    width: 200,
    height: 200,
    position: 'relative',
    marginBottom: theme.spacing(2),
    '& svg': {
      display: 'block',
      width: 200,
      height: 200,
    },
    '& span': {
      fontWeight: 500,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '& div': {
      marginTop: '46px',
    },
  },
  subtitle: {
    textAlign: 'center',
    fontWeight: 500,
  },
  [theme.breakpoints.down('md')]: {
    protectInfoCard: {
      background: 'none',
      padding: 0,
      border: 0,
    },
    textContainer: {
      padding: 0,
    },
  },
  upgradeMessage: {
    paddingTop: theme.spacing(3),
  },
}));

const allPlans = ['free', 'trial', 'premium', 'pro', 'vip', 'executive'];

const nextPlan = plan => {
  if (plan === 'executive') {
    return null;
  }
  return allPlans[allPlans.indexOf(plan) + 1];
};

const scanCountForPlan = (sites, plan) => {
  return sites.reduce((total, site) => {
    return total + Number(site.userScanSettings.internal[plan].time);
  }, 0);
};

export default ({ innerRef, sites, user, percentage, alerts, alertTypePercentage }) => {
  const classes = useStyles();

  const plan = user.plan.label.toLowerCase();
  const upgrade = nextPlan(plan);

  return (
    <Card ref={innerRef} className={classes.protectInfoCard} style={{ marginBottom: 40 }}>
      <CardContent className={classes.textContainer}>
        <Typography gutterBottom variant="h4">
          My Insights
        </Typography>
        <Typography variant="body1">
          {alerts
            ? `Safe Shepherd is scanning ${scanCountForPlan(
                sites,
                plan
              )} websites to find your personal information. As we find records we think are yours, we’ll notify you instantly via email and they will show up below in your Alert Vault. When a record is automatically removed or manually removed by you, your protection level will increase.`
            : 'We’ve started scanning malicious sites for your personal information and will list them here in your Alert Vault and notify you via email as soon as we find records we think are yours. In the meantime, please make sure you’ve added all your aliases, emails, phone numbers, and addresses for the most complete results.'}
        </Typography>
        {upgrade && (
          <Typography variant="body1" className={classes.upgradeMessage}>
            <Link to="/settings/membership">Upgrade</Link> to the {upgrade.charAt(0).toUpperCase() + upgrade.slice(1)}{' '}
            plan now to unlock up to {scanCountForPlan(sites, upgrade)} websites.
          </Typography>
        )}
      </CardContent>
      <Hidden mdDown>
        <CardContent className={classes.protectInfoProgress}>
          <div className={classes.circleContainer}>
            <ProgressCircle alertTypePercentage={alertTypePercentage} />
            <Typography variant="subtitle1" className={classes.subtitle}>
              {percentage}% Protected
            </Typography>
          </div>
        </CardContent>
      </Hidden>
    </Card>
  );
};
