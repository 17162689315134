import React from 'react';
import { Container, Grid, Typography, CircularProgress, Divider, Button } from '@material-ui/core';
import useFirebaseSubscribe from '../hooks/useFirestoreSubscribe';
import { makeStyles } from '@material-ui/core/styles';
import Editor from 'rich-markdown-editor';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  mainContent: {
    padding: theme.spacing(7, 10),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(7, 6.5),
    },
  },
  faqBox: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    position: 'relative',
    '& > h5': {
      fontWeight: 600,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    '& > p': {
      marginBottom: theme.spacing(4),
      fontWeight: 200,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
    },
  },
  link: {
    textDecoration: 'none',
  },
}));

export default () => {
  const classes = useStyles();
  const [faqs, isLoading] = useFirebaseSubscribe('faq', ['enabled', '==', true], 'order');

  if (isLoading) {
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <CircularProgress size={40} />
      </Grid>
    );
  }

  return (
    <Container maxWidth="lg" className={classes.mainContent}>
      <Typography variant="subtitle1" color="textSecondary">
        FAQ
      </Typography>
      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h4">We’re Here To Help</Typography>
        </Grid>
        <Grid item>
          <a href="mailto:support@safeshepherd.com" className={classes.link}>
            <Button variant="contained" color="secondary">
              E-mail Customer Support
            </Button>
          </a>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      {faqs.map((faq, index) => {
        const anchor = faq.question
          .replace(/[^a-zA-Z ]/g, '')
          .replace(/ +/g, '-')
          .toLowerCase();

        return (
          //turn off linking as this is a legitimate anchor
          // eslint-disable-next-line
          <a name={anchor}>
            <Grid item xs={12} sm={6} lg={9} className={classes.faqBox} key={index}>
              <Typography variant="h5" color="primary">
                {faq.question}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                <Editor theme={{ background: 'transparent' }} defaultValue={faq.answer} readOnly />
              </Typography>
            </Grid>
          </a>
        );
      })}
    </Container>
  );
};
