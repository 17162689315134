import { RemovedIcon, PendingIcon } from './Icons';

export const ALERT_TYPES = {
  REMOVED: 'removed',
  REQUESTED: 'requested',
  ADMIN_OPT_OUT: 'adminOptOut',
  MANUAL: 'manual',
  UNSUPPORTED: 'unsupported',
};

export const ALERT_UI = {
  [ALERT_TYPES.REMOVED]: {
    icon: RemovedIcon,
    color: '#2CD9DA',
    label: 'Removed',
  },
  [ALERT_TYPES.REQUESTED]: {
    icon: RemovedIcon,
    color: '#2CD9DA',
    label: 'Removal Requested',
  },
  [ALERT_TYPES.MANUAL]: {
    icon: RemovedIcon,
    color: '#2CD9DA',
    label: 'Manual Removal',
  },
  [ALERT_TYPES.ADMIN_OPT_OUT]: {
    icon: RemovedIcon,
    color: '#2CD9DA',
    label: 'Admin Opted Out',
  },
  [ALERT_TYPES.UNSUPPORTED]: {
    icon: PendingIcon,
    color: '#FFCF2C',
    label: 'Manual Removal Required',
  },
};
