import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, OutlinedInput, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { functions, auth } from '../../firebase';
import useAsync from '../../hooks/useAsync';
import FormButtons from './FormButtons';
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles(theme => ({
  loginFieldsContainer: {
    margin: `${theme.spacing(6)}px 0`,
    '& > *': {
      margin: `${theme.spacing(1)}px 0`,
    },
  },
  linkForgotPassword: {
    display: 'inline',
    color: theme.palette.link,
    textDecoration: 'none',
    marginLeft: 'auto',
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  paper: {
    maxWidth: 330,
    minHeight: 350,
    padding: theme.spacing(4, 5),
    margin: '0 auto',
  },
  error: {
    marginTop: theme.spacing(2.5),
  },
}));

const useStylesInputFields = makeStyles(theme => ({
  inputBox: {
    borderRadius: 8,
    fontSize: '.9rem',
    height: 43,
    marginTop: theme.spacing(1.4),
  },
  inputSection: {
    marginTop: theme.spacing(2),
  },
}));

const InputField = ({ label, type, value, setInputValue, placeholder }) => {
  const classes = useStylesInputFields();

  return (
    <div className={classes.inputSection}>
      <Typography variant="body1">{label}</Typography>
      <OutlinedInput
        className={classes.inputBox}
        type={type}
        value={value}
        onChange={e => setInputValue(e.target.value)}
        placeholder={placeholder}
        autoComplete="on"
        fullWidth
        required
      />
    </div>
  );
};

const signInWithCreds = async ({ email, password, history }) => {
  const { data: token } = await functions.httpsCallable('loginWithCreds')({ email, password });

  // if token is 'existed', it means the email has a user in old SS
  if (token === 'existed') {
    history.push('/pending-account-update');
  } else {
    const result = await auth().signInWithCustomToken(token);
    mixpanel.track('$login', { user_id: result.user.uid });
  }
};

export default () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const [{ isLoading, error }, doLogin] = useAsync(signInWithCreds);

  return (
    <>
      <Paper elevation={0} variant="outlined" className={classes.paper}>
        <form
          onSubmit={e => {
            e.preventDefault();
            doLogin({ email, password, history });
          }}
        >
          <Typography variant="h4" align="center">
            Welcome to
            <br />
            Safe Shepherd!
          </Typography>

          <InputField
            label="Username"
            type="email"
            value={email}
            setInputValue={setEmail}
            placeholder="Enter email address"
          />
          <InputField
            label="Password"
            type="password"
            value={password}
            setInputValue={setPassword}
            placeholder="Enter your password"
          />

          {error && (
            <Typography className={classes.error} variant="subtitle2" color="error">
              {error.message}
            </Typography>
          )}
          <FormButtons
            isLoading={isLoading}
            primaryText={'Login'}
            secondaryText={'Forgot password?'}
            secondaryPath={'/reset'}
          />
        </form>
      </Paper>

      <div style={{ marginTop: 10, textAlign: 'center' }}>
        <Link to="/signup">Create new Account</Link>
      </div>
    </>
  );
};
