import React from 'react';
import { Grid, Container, Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch } from 'react-router-dom';
import LoginForm from '../components/Auth/LoginForm';
import SignUpForm from '../components/Auth/SignUpForm';
import ResetForm from '../components/Auth/ResetForm';
import loginImage from '../images/login.png';

const useStyles = makeStyles(theme => ({
  imageContainer: {
    // should be replaced with an img of the site that will be paralaxed
    width: '35%',
    height: '90vh',
    position: 'fixed',
    right: 0,
    bottom: 0,
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },
  image: {
    height: '100%',
    width: '160%',
    borderRadius: '40px',
    backgroundSize: 'cover',
    background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 45% , rgba(250, 250, 250, 1)),url(${loginImage})`,
    margin: '2% 4% 4% -4%',
  },
  mainContent: {
    margin: 'auto 0',
    '& > h3': {
      fontWeight: 600,
      fontSize: '3rem',
    },
    '& > h5': {
      fontWeight: 600,
    },
    '& > h6': {
      fontWeight: 200,
    },
  },
  GridContainer: {
    height: '100%',
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth={false}>
        <Grid className={classes.GridContainer} container direction="row" justify="flex-end" spacing={3}>
          <Grid item xs={12} md={6} lg={4} className={classes.mainContent}>
            <Switch>
              <Route path="/pending-account-update">
                <Typography variant="h3" color="primary">
                  Welcome to
                  <br />
                  Safe Shepherd!
                </Typography>
                <br />
                <br />
                <br />
                <Typography variant="h5" color="primary">
                  Account Verification Required
                </Typography>
                <br />
                <Typography variant="h6" color="textSecondary">
                  We take security seriously so from time-to-time we’ll ask you to update your account details. Please
                  click the link in the email we just sent you to update and verify your details and login to your
                  account.
                </Typography>
              </Route>
              <Route path="/login" component={LoginForm} />
              <Route path="/reset" component={ResetForm} />
              <Route path="/signup" component={SignUpForm} />
            </Switch>
          </Grid>

          <Hidden smDown>
            <Grid item md={5} lg={7} />
          </Hidden>
        </Grid>
      </Container>
      <Hidden smDown>
        <div className={classes.imageContainer}>
          <div className={classes.image} />
        </div>
      </Hidden>
    </>
  );
};
