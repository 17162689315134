import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { ALERT_UI } from './constants';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(6.5),
    padding: `${theme.spacing(6.25)}px ${theme.spacing(7.5)}px ${theme.spacing(6.25)}px ${theme.spacing(7.5)}px`,
    display: 'flex',
    flexDirection: 'row',
    border: props => `1px solid ${ALERT_UI[props.type].color}`,
    boxShadow: '0px 15px 20px rgba(0,0,0,0.05)',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(4)}px ${theme.spacing(6)}px ${theme.spacing(4)}px ${theme.spacing(8)}px`,
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(4),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color: 'rgb(255,165,0)',
    },
    '& > a': {
      color: theme.palette.link,
      textDecoration: 'none',
    },
  },
  actionButton: {
    color: 'white !important',
    display: 'block',
    width: '100%',
    padding: theme.spacing(2),
    boxShadow: 'none',
    marginRight: 0,
    marginLeft: 0,
    backgroundColor: props => ALERT_UI[props.type].color,
    '&:hover': {
      backgroundColor: props => ALERT_UI[props.type].color,
    },
  },
  viewDetail: {
    textAlign: 'left',
  },
  icon: {
    flexShrink: 0,
    // indent into the card's left padding
    marginLeft: -45,
    marginRight: 15,
    [theme.breakpoints.down('md')]: {
      marginLeft: -40,
      marginRight: 10,
    },
  },
}));

const HeadingText = ({ preTitle, title, postTitle, color }) => (
  <span style={{ color: 'black' }}>
    {preTitle} <span style={{ color }}>{title}</span> {postTitle}
  </span>
);

export default props => {
  const classes = useStyles(props);

  const { id, type, site, sites, className, createdAt } = props;
  const scanDate = format(createdAt, 'MM/dd/yyyy');
  const { label } = site;
  const siteId = sites.sites.find(s => s.id === site.id);

  const singularizePII = {
    aliases: 'name',
    addresses: 'address',
    emails: 'email',
    phoneNumbers: 'phone number',
  };

  const siteSupportedData = Object.keys(siteId.supportedPII)
    .filter(key => siteId.supportedPII[key])
    .map(key => {
      const singularizedKey = singularizePII[key];
      return singularizedKey;
    });

  const foundPII = siteSupportedData.join(', ').replace(/, ([^,]*)$/, ' and $1');

  const Icon = ALERT_UI[type].icon;

  let preTitle,
    postTitle = '',
    shouldViewDetails = false,
    color = '#2CD9DA';

  switch (type) {
    case 'removed':
      preTitle = `Safe Sepherd has auto-removed your ${foundPII} from`;
      break;

    case 'requested':
      preTitle = `Safe Shepherd submitted an opt-out for your ${foundPII} on`;
      shouldViewDetails = true;
      break;

    case 'unsupported':
      preTitle = `Safe Shepherd has found your ${foundPII} on`;
      postTitle = 'for you to manually remove.';
      color = '#FFCF2C';
      shouldViewDetails = true;
      break;

    case 'manual':
      preTitle = 'Safe Shepherd will continue to monitor ';
      postTitle = 'to confirm the removal of your information.';
      break;

    default:
      preTitle = `Safe Shepherd has removed your ${foundPII} from`;
      break;
  }

  return (
    <Card className={`${classes.root} ${className}`}>
      <Icon className={classes.icon} />
      <div className={classes.contentContainer}>
        <Typography variant="h5">
          <HeadingText {...{ preTitle, title: label, postTitle, color }} />
        </Typography>
        {type !== 'unsupported' && (
          <Typography variant="subtitle2" style={{ color: '#b5b5b5', fontWeight: 600 }}>
            Submitted on: {scanDate}
          </Typography>
        )}
        {shouldViewDetails && (
          <Link to={`?dialog=${id}`} className={classes.viewDetail}>
            &gt; View Details
          </Link>
        )}
      </div>
    </Card>
  );
};
