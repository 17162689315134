import React, { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BillingForm } from '../components/Billing/BillingForm';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Card, CardContent, Typography, Divider } from '@material-ui/core';
import useFirestoreSubscribe from '../hooks/useFirestoreSubscribe';

import { useSelector } from 'react-redux';
import { isBillingValid, daysLeftInTrial, getUser } from '../store/selectors/user';
import { Redirect, useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const useStyles = makeStyles(theme => ({
  cardStyle: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    padding: theme.spacing(2.5),
    border: 'none',
    [theme.breakpoints.down('md')]: {
      padding: 'unset',
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: 29,
    },
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const billingValid = useSelector(isBillingValid);
  const daysOnTrial = useSelector(daysLeftInTrial);
  const user = useSelector(getUser);
  const [sites, isLoading] = useFirestoreSubscribe('sites');
  const trafficType = user?.isAdmin ? '?internal=true' : '';

  useEffect(() => {
    history.push({ search: trafficType });
    mixpanel.track('Checkout View');
    // eslint-disable-next-line
  }, []);

  if (billingValid && !daysOnTrial) {
    return <Redirect to={{ pathname: '/alerts', search: trafficType }} />;
  }

  if (isLoading) {
    return <></>;
  }

  return (
    <Container maxWidth="lg">
      <Card className={classes.cardStyle}>
        <CardContent>
          <Elements stripe={stripePromise}>
            <Typography className={classes.title} gutterBottom variant="h4">
              Select a Plan to Activate Safe Shepherd Protection
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle1">
              {user.plan?.id === 'trial' &&
                `The Trial Plan ONLY monitors ${sites.reduce((total, current) => {
                  return total + Number(current.userScanSettings.internal[user.plan.id].time);
                }, 0)} sites for your Name and Email. Upgrade today to unlock additional protection before it expires.`}
              {user.plan?.id === 'free' &&
                'We’re not monitoring any sites for your personal information so your privacy is at risk! Upgrade now to turn protection back on.'}
            </Typography>
            <Divider className={classes.divider} />
            <BillingForm sites={sites} />
          </Elements>
        </CardContent>
      </Card>
    </Container>
  );
};
