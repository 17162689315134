import React from 'react';
import { Typography, Checkbox, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/selectors/user';
import { db } from '../../firebase';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(4),
    '& > h5': {
      marginBottom: theme.spacing(4),
      fontWeight: 400,
    },
  },
  checkBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    '& > *:first-child': {
      display: 'flex',
      marginBottom: theme.spacing(7),
    },
    '& > *:not(:first-child)': {
      display: 'flex',
    },
  },
  notificationText: {
    marginTop: theme.spacing(1.5),
    '& > h6': {
      marginBottom: theme.spacing(1),
    },
  },
  box: {
    '&.MuiCheckbox-root': {
      color: theme.palette.primary.main,
    },
  },
}));

export default () => {
  const classes = useStyles();
  const user = useSelector(getUser);

  const notifications = {
    isPrivacyEmailDisabled: (user.notifications && user.notifications.isPrivacyEmailDisabled) || false,
    isServiceEmailDisabled: (user.notifications && user.notifications.isServiceEmailDisabled) || false,
  };

  const toggleNotifications = notifications => {
    db.collection('users').doc(user.uid).update({ notifications });
  };

  const checkBoxes = [
    {
      title: 'Privacy Updates',
      description: 'Please send me an email when you find my personal information exposed online.',
      checked: !notifications.isPrivacyEmailDisabled,
      onChange: () =>
        toggleNotifications({ ...notifications, isPrivacyEmailDisabled: !notifications.isPrivacyEmailDisabled }),
    },
    {
      title: 'Service Updates',
      description: 'Let me know when Safe Shepherd has any special news, advice, or newsletters.',
      checked: !notifications.isServiceEmailDisabled,
      onChange: () =>
        toggleNotifications({ ...notifications, isServiceEmailDisabled: !notifications.isServiceEmailDisabled }),
    },
  ];

  return (
    <div className={classes.container}>
      <Typography variant="h5">Notifications</Typography>

      <div className={classes.checkBoxContainer}>
        {checkBoxes.map(checkBox => {
          return (
            <div key={checkBox.title}>
              <Checkbox className={classes.box} checked={checkBox.checked} onChange={checkBox.onChange} />
              <Grid item xs={12} md={7} lg={6} className={classes.notificationText}>
                <Typography variant="h6">{checkBox.title}</Typography>
                <Typography variant="body1" color="textSecondary">
                  {checkBox.description}
                </Typography>
              </Grid>
            </div>
          );
        })}
      </div>
    </div>
  );
};
