import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import { Typography } from '@material-ui/core';
import Masonry from 'react-masonry-css';
import AlertCard from './Card';
import ListFilter from './ListFilter';
import useFirestoreSubscribe from '../../hooks/useFirestoreSubscribe';
import { getUser } from '../../store/selectors/user';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { ALERT_TYPES } from './constants';

const useStyles = makeStyles(theme => {
  const MASONRY_GUTTER = theme.spacing(4);
  return {
    listHeader: {
      display: 'flex',
      marginBottom: theme.spacing(4),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    listHeaderSubtitle: {
      color: theme.palette.grey[400],
      marginTop: theme.spacing(1),
    },
    masonry: {
      marginLeft: -MASONRY_GUTTER, // gutter size offset
      width: 'auto',
      '& > *': {
        display: 'inline-block',
        verticalAlign: 'top',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100% !important',
        marginRight: -MASONRY_GUTTER, // gutter size offset
      },
    },
    masonryColumn: {
      paddingLeft: MASONRY_GUTTER, // gutter size
      backgroundClip: 'padding-box',
      boxSizing: 'border-box',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        paddingLeft: 'unset !important',
        display: 'flex',
        flexDirection: 'column',
      },
    },
    masonryItem: {
      marginBottom: MASONRY_GUTTER, // matches parent gutter
    },
    [theme.breakpoints.down('xs')]: {
      listHeader: {
        flexDirection: 'column',
        alignItems: 'stretch',
      },
      listHeaderTextContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginBottom: theme.spacing(2),
        '& > h4': {
          marginRight: theme.spacing(1.5),
        },
      },
    },
  };
});

export default sites => {
  const classes = useStyles();
  const theme = useTheme();
  const user = useSelector(getUser);
  const [alerts, isLoading] = useFirestoreSubscribe('alerts', [
    ['userId', '==', user.uid],
    ['type', 'in', ['unsupported', 'removed', 'requested', 'manual', 'adminOptOut']],
  ]);

  const [selectedType, setSelectedType] = useState('all');

  if (isLoading) {
    return <></>;
  }

  const lastUpdated =
    alerts.reduce((lastUpdated, alert) => {
      return alert.createdAt > lastUpdated ? alert.createdAt : lastUpdated;
    }, 0) || new Date();

  return (
    <>
      <div className={classes.listHeader}>
        <div className={classes.listHeaderTextContainer}>
          <Typography variant="h4">Alert Vault</Typography>
          <Typography variant="subtitle2" className={classes.listHeaderSubtitle}>
            {Boolean(alerts.length) && `Last Updated: ${format(lastUpdated, 'LLL d y')}`}
          </Typography>
        </div>
        <ListFilter selected={selectedType} alerts={alerts} onSelect={type => setSelectedType(type)} />
      </div>
      <Masonry
        breakpointCols={{
          default: 2,
          [theme.breakpoints.values.md]: 1,
        }}
        className={classes.masonry}
        columnClassName={classes.masonryColumn}
      >
        {alerts
          .filter(alert => {
            if (selectedType === 'all') {
              return true;
            }

            if (selectedType === ALERT_TYPES.REMOVED) {
              return [
                ALERT_TYPES.REQUESTED,
                ALERT_TYPES.REMOVED,
                ALERT_TYPES.MANUAL,
                ALERT_TYPES.ADMIN_OPT_OUT,
              ].includes(alert.type);
            }

            return alert.type === selectedType;
          })
          .map((alert, i) => (
            <AlertCard sites={sites} className={classes.masonryItem} key={alert.scanTransactionId} {...{ ...alert }} />
          ))}
      </Masonry>
    </>
  );
};
