import React from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyle = makeStyles(theme => ({
  profilePhoto: {
    background: theme.palette.grey[200],
    border: `3px solid ${theme.palette.grey[200]}`,
    width: theme.spacing(7.5),
    height: theme.spacing(7.5),
  },
}));

export default ({ className, ...other }) => {
  const classes = useStyle();
  const user = useSelector(({ user }) => user);

  return <Avatar src={user.profilePhoto} className={`${classes.profilePhoto} ${className}`} {...other} />;
};
