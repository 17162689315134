import React from 'react';
import classnames from 'classnames';
import { Card, Typography, Grid, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircleOutline, RoomService, Mail, Lock } from '@material-ui/icons';
import { priceAfterCoupon, formatPrice } from '../../components/utils/priceAfterCoupon';

const useStyles = makeStyles(theme => ({
  planCard: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(5),
    borderRadius: theme.spacing(5),
    boxSizing: 'border-box',
    width: '100%',
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
    '& > h5': {
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  },
  gridChild: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  pricing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: '1.875rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
      textAlign: 'center',
    },
  },
  discount: {
    color: theme.palette.secondary.main,
  },
  title: {
    marginTop: theme.spacing(2),
    fontWeight: 400,
  },
  benefitsContainer: {
    marginTop: theme.spacing(1),
  },
  benefit: {
    fontSize: 12,
    color: theme.palette.link,
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      fontSize: 15,
      color: '#CBCDD5',
      marginRight: theme.spacing(0.5),
    },
  },
  sitesCount: {
    color: '#2CD9DA',
  },
  checkMark: {
    color: `${theme.palette.secondary.main} !important`,
  },
  service: {
    color: `#FFD700 !important`,
  },
  selectedCard: {
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  changeButton: {
    margin: theme.spacing(2, 'auto', 2, 'auto'),
  },
  strikethrough: {
    position: 'relative',
    color: theme.palette.text.disabled,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: 0,
      width: '100%',
      height: '2px',
      background: '#0F0F0F',
      transform: 'rotate(-7deg)',
    },
  },
  discountedRate: {
    fontSize: '1.75rem',
    color: theme.palette.secondary.main,
  },
}));

const formatPlan = plan => {
  if (plan === 'vip') {
    return plan.toUpperCase();
  }
  return plan[0].toUpperCase() + plan.substring(1);
};

const getNumber = number => {
  if (number > 10) {
    return 'Unlimited';
  }
  return number;
};

const isPlanSelected = (plan, selectedType, currentPlan) => {
  if (!!currentPlan) {
    return plan.cycle === currentPlan.cycle && plan.type === currentPlan.type;
  } else {
    return plan.type === selectedType;
  }
};

export default ({
  onChange,
  selectedType,
  plans,
  sites,
  currentPlan,
  doChangePlan,
  isLoading,
  percentOff,
  amountOff,
  couponDuration,
  durationInMonths,
}) => {
  const classes = useStyles();

  const getFeatureName = feature => {
    if (feature.type === 'check') {
      return (
        <>
          <CheckCircleOutline className={classes.checkMark} /> {feature.name}
        </>
      );
    }
  };

  const getSupport = plan => {
    const vipSupport = plan.features.find(feature => feature.type === 'service');

    if (Boolean(vipSupport) && Boolean(vipSupport.value)) {
      return (
        <>
          <RoomService className={classes.service} />
          {formatPlan(plan.type)} {vipSupport.value || 'M-F 9am-5pm'}
        </>
      );
    }

    return (
      <>
        <Mail />
        {formatPlan(plan.type)} M-F 9am-5pm
      </>
    );
  };

  const renderDiscount = plan => {
    if (plan.cycle === 'annual') {
      return `Annual: Savings of over ${plan.discount}% over Monthly`;
    } else if (plan.cycle === 'biennial') {
      return `2-Year: Savings of over ${plan.discount}% over Monthly`;
    }
    return '';
  };

  const sitesCount = sites.reduce(
    (totals, site) => {
      totals.premium = totals.premium + Number(site.userScanSettings.internal.premium.time);
      totals.pro = totals.pro + Number(site.userScanSettings.internal.pro.time);
      totals.vip = totals.vip + Number(site.userScanSettings.internal.vip.time);
      return totals;
    },
    { premium: 0, pro: 0, vip: 0, executive: 'Unlimited' }
  );

  return (
    <>
      {plans.map(plan => {
        const adjustedPrice = priceAfterCoupon(plan.price, amountOff, percentOff);
        const isAnnualDiscount = durationInMonths ? plan.cycle === 'annual' : plan.cycle === 'monthly';
        const isUnlimitedCoupon = couponDuration === 'forever' || isAnnualDiscount;
        const isDiscountVisible =
          adjustedPrice !== formatPrice(plan.price) && currentPlan?.id !== plan.id && isUnlimitedCoupon;
        return (
          <Grid container key={plan.label} item md={3} spacing={2} className={classes.gridChild}>
            <Card
              key={plan.id}
              className={classnames({
                [classes.planCard]: true,
                [classes.selectedCard]: isPlanSelected(plan, selectedType, currentPlan),
              })}
              onClick={() => onChange && onChange(plan.type)}
            >
              <Typography variant="subtitle1" className={classes.discount}>
                {plan.type === 'pro' && 'Most Popular'}&nbsp;
              </Typography>
              <Typography variant="h5" color="textSecondary">
                {plan.label}
              </Typography>
              <Typography variant="h4" className={classes.pricing}>
                <span className={isDiscountVisible ? classes.strikethrough : null}>${formatPrice(plan.price)}</span>
                {isDiscountVisible && <span className={classes.discountedRate}> ${adjustedPrice}</span>}
              </Typography>
              <div className={classes.discount}>{renderDiscount(plan)}</div>
              <Grid container spacing={1} className={classes.benefitsContainer}>
                <Grid item xs={12} className={classes.title}>
                  {formatPlan(plan.type)} Protection
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" className={`${classes.benefit} ${classes.sitesCount}`}>
                    <Lock /> <strong>{sitesCount[plan.type]}</strong>&nbsp;Sites Monitored
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1" className={classes.benefit}>
                    <Lock /> <strong>{getNumber(plan.protection.aliases)}</strong>&nbsp;Alias
                    {plan.protection.aliases > 1 && 'es'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" className={classes.benefit}>
                    <Lock /> <strong>{getNumber(plan.protection.emails)}</strong>&nbsp;Emails
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" className={classes.benefit}>
                    <Lock /> <strong>{getNumber(plan.protection.phoneNumbers)}</strong>&nbsp;Phone Numbers
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" className={classes.benefit}>
                    <Lock /> <strong>{getNumber(plan.protection.addresses)}</strong>&nbsp;Addresses
                  </Typography>
                </Grid>

                <Grid item xs={12} className={classes.title}>
                  Privacy Support
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" className={classes.benefit}>
                    {getSupport(plan)}
                  </Typography>
                </Grid>

                <Grid item xs={12} className={classes.title}>
                  {formatPlan(plan.type)} Features
                </Grid>
                {plan.features
                  .filter(feature => feature.type === 'check')
                  .map(feature => {
                    return (
                      <Grid item key={`${plan.id}:${feature.id}`} xs={12}>
                        <Typography variant="body1" className={classes.benefit}>
                          {getFeatureName(feature)}
                        </Typography>
                      </Grid>
                    );
                  })}
                {!!currentPlan && !isPlanSelected(plan, selectedType, currentPlan) && (
                  <Button
                    className={classes.changeButton}
                    variant="outlined"
                    color="primary"
                    disabled={isLoading}
                    onClick={() => doChangePlan(plan)}
                  >
                    {isLoading ? <CircularProgress size={25} /> : 'Change Plan'}
                  </Button>
                )}
                {!!currentPlan && isPlanSelected(plan, selectedType, currentPlan) && (
                  <Button className={classes.changeButton} variant="outlined" disabled={true} color="secondary">
                    Current Plan
                  </Button>
                )}
              </Grid>
            </Card>
          </Grid>
        );
      })}
    </>
  );
};
