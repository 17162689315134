import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Divider, Popover, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { InfoOutlined as InfoIcon, Close as CloseIcon } from '@material-ui/icons';
import PropertyEditor from './PropertyEditor';
import { setUserPII } from '../../store/actions/user';
import { getUser } from '../../store/selectors/user';
import ProfilePhoto from './ProfilePhoto';

const useStyles = makeStyles(theme => ({
  header: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  profilePhoto: {
    marginRight: theme.spacing(3),
  },
  section: {
    marginTop: theme.spacing(3.5),
  },
  infoSection: {
    display: 'flex',
    alignItems: 'center',
  },
  tipPopup: {
    background: theme.palette.primary.main,
    color: 'white',
    padding: theme.spacing(3),
    width: 240,
  },
  tipPopupClose: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
    padding: 0,
    '& svg': {
      color: 'white',
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },
  tipPopupText: {
    fontWeight: 100,
  },
  [theme.breakpoints.down('md')]: {
    header: {
      justifyContent: 'initial',
      marginTop: theme.spacing(4),
    },
    infoSection: {
      justifyContent: 'center',
    },
  },
  limitPII: {
    background: theme.palette.secondary.main,
    color: 'white',
  },
}));

export default () => {
  const classes = useStyles();
  const [isTipOpen, setTipOpen] = useState(false);
  const tipButton = useRef();
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  return (
    <>
      <Typography variant="h5" color="primary" className={classes.header} gutterBottom>
        Protected Information
        <IconButton ref={tipButton} onClick={() => setTipOpen(true)}>
          <InfoIcon />
        </IconButton>
        <Popover
          anchorEl={tipButton.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isTipOpen}
          onBlur={() => setTipOpen(false)}
          classes={{ paper: classes.tipPopup }}
        >
          <IconButton className={classes.tipPopupClose} onClick={() => setTipOpen(false)}>
            <CloseIcon />
          </IconButton>
          <Typography variant="subtitle2" className={classes.tipPopupText}>
            For optimal results, please add as many verified data points as your plan allows. We will protect these
            emails, addresses, and phone numbers by comparing them to records we find on malicious websites.
          </Typography>
        </Popover>
      </Typography>
      <Divider />
      <div className={`${classes.section} ${classes.infoSection}`}>
        <Link className={classes.profilePhoto} to="/settings">
          <ProfilePhoto />
        </Link>
        <div>
          {user.plan && (
            <Typography color="secondary" variant="subtitle1">
              {user.plan.label}
            </Typography>
          )}
          <Typography variant="h5">
            {user.firstName} {user.lastName}
          </Typography>
        </div>
      </div>
      {user.PII &&
        Object.keys(user.PII).map(key => (
          <div className={classes.section} key={key}>
            <PropertyEditor
              user={user}
              fieldName={key}
              values={user.PII[key]}
              isAddress={key === 'addresses'}
              isPhoneNumber={key === 'phoneNumbers'}
              isAlias={key === 'aliases'}
              onChange={newVals => {
                dispatch(setUserPII(key, newVals));
              }}
            />
          </div>
        ))}
    </>
  );
};
