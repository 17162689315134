import React from 'react';
import { Doughnut } from 'react-chartjs-2';

export default ({ alertTypePercentage, isTopBar = false }) => {
  const isAlertsCount = alertTypePercentage.reduce((acc, curr) => acc + curr);
  const chartData = Boolean(isAlertsCount)
    ? {
        data: alertTypePercentage,
        backgroundColor: ['#2CD9DA', '#FFCF2C'],
        hoverBackgroundColor: ['#21A5A5', '#FFC300'],
      }
    : { data: [100], backgroundColor: ['#b5b5b5'] };

  const data = {
    datasets: [
      {
        ...chartData,
      },
    ],
    labels: ['Removed', 'Active'],
  };

  return (
    <div>
      <Doughnut
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          tooltips: {
            enabled: Boolean(isAlertsCount) && !isTopBar,
            callbacks: {
              title: (item, data) => `${data.labels[item[0].index]} records`,
              label: (item, data) => {
                const label = data.labels[item.index];
                const dataset = data.datasets[0].data[item.index];
                return label === 'Active'
                  ? `${dataset} records ready for manual removal`
                  : `${dataset} records removed`;
              },
            },
            bodyFontSize: 10,
            xPadding: 8,
            yPadding: 8,
            displayColors: false,
          },
          legend: {
            ...((!isAlertsCount || isTopBar) && { display: false }),
            labels: {
              fontSize: 10,
              padding: 4,
              boxWidth: 20,
            },
          },
          cutoutPercentage: 70,
        }}
      />
    </div>
  );
};
