import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import { store } from './store';
import mixpanel from 'mixpanel-browser';
import { BrowserRouter as Router } from 'react-router-dom';

const muiTheme = createMuiTheme(theme);
mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);

ReactDOM.render(
  <ThemeProvider theme={muiTheme}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
