import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, IconButton, makeStyles, Snackbar, CircularProgress } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import PropertyEditorField from './PropertyEditorField';
import { InputAdd } from '../../images';
import useFirestoreSubscribe from '../../hooks/useFirestoreSubscribe';

const formatLabel = fieldName => {
  return fieldName.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) {
    return str.toUpperCase();
  });
};

const useStyles = makeStyles(theme => ({
  header: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addButton: {
    padding: 0,
    border: `1px solid ${theme.palette.primary.main}`,
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
  snackbar: {
    backgroundColor: theme.palette.secondary.main,
    textAlign: 'center',
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
}));

export default ({ fieldName, values = [], onChange, isAddress, isPhoneNumber, isAlias, user }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [plans, isLoadingPlans] = useFirestoreSubscribe('plans');

  const userPlanLimit = plans.find(plan => plan.type === user.plan.type);
  const isAbovePlanLimit = index => (index + 1 > userPlanLimit.protection[fieldName] ? true : false);

  if (isLoadingPlans) {
    return <CircularProgress />;
  }

  const Alert = props => {
    return <MuiAlert className={classes.snackbar} elevation={6} variant="filled" {...props} />;
  };

  const defaultNewValue = isAddress
    ? {
        street: '',
        apt: '',
        city: '',
        state: '',
        zip: '',
      }
    : isAlias
    ? { firstName: '', middleName: '', lastName: '' }
    : '';

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h6">Protected {formatLabel(fieldName)}</Typography>
        <IconButton
          className={classes.addButton}
          onClick={() => {
            if (user.PII[fieldName].length < userPlanLimit.protection[fieldName]) {
              setIsNew(true);
              onChange([...values, defaultNewValue]);
            } else {
              setOpen(true);
            }
          }}
        >
          <InputAdd />
        </IconButton>
        <Snackbar open={open} onClose={() => setOpen(false)}>
          <Alert onClose={() => setOpen(false)} severity="warning">
            Your current plan only allows for {userPlanLimit.protection[fieldName]} protected{' '}
            {formatLabel(fieldName).toLowerCase()}. To enhance your protection{' '}
            <Link to="/settings/membership">upgrade now!</Link>
          </Alert>
        </Snackbar>
      </div>
      {values.map((value, index) => {
        return (
          <PropertyEditorField
            key={fieldName + index}
            isAbovePlanLimit={isAbovePlanLimit(index)}
            value={value}
            isAddress={isAddress}
            isPhoneNumber={isPhoneNumber}
            isAlias={isAlias}
            onChange={newVal => {
              onChange([...values.slice(0, index), newVal, ...values.slice(index + 1)]);
            }}
            onDelete={() => {
              onChange(values.slice(0, index).concat(values.slice(index + 1)));
            }}
            isNew={index + 1 === values.length && isNew}
            setIsOpen={setIsNew}
          />
        );
      })}
    </>
  );
};
