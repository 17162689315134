import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import { useHistory, useLocation } from 'react-router-dom';
import QueryString from 'query-string';

const MixpanelPageView = () => {
  const history = useHistory();
  const location = useLocation();

  const trackLocation = location => {
    if (location.search) {
      //e-mails pass us some parameters to email an email click.
      const params = QueryString.parse(location.search);
      if (Boolean(params.distinctId) && Boolean(params.template)) {
        mixpanel.identify(params.distinctId);
        mixpanel.track('Email Click', { template: params.template });
      }
    }

    mixpanel.track('Page View', { page: location.pathname });
  };

  useEffect(() => {
    trackLocation(location);
    return history.listen(trackLocation);
  }, [history, location]);

  return null;
};

export default MixpanelPageView;
