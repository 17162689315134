import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import commonAlertStyles from './CommonAlertStyles';

export default ({ message, id = '', link = '' }) => {
  const classes = commonAlertStyles();
  const [open, setOpen] = useState(true);

  return (
    <Collapse in={open}>
      <Alert
        icon={
          <IconButton style={{ padding: '0px' }} onClick={() => setOpen(false)}>
            <CloseIcon style={{ color: 'white', fontSize: 'inherit' }} />
          </IconButton>
        }
        className={classes.snackbar}
      >
        <div className={classes.textContent} id={id}>
          {message}
          <Link className={classes.link} to={link}>
            Upgrade now
          </Link>
        </div>
      </Alert>
    </Collapse>
  );
};
