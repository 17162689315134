import React from 'react';
import { ALERT_TYPES, ALERT_UI } from '../constants';
import getIconContainerStyles from './getIconContainerStyles';
import { Check as Icon } from '@material-ui/icons';

export default props => (
  <div className={props.className} style={getIconContainerStyles(ALERT_UI[ALERT_TYPES.REMOVED].color)}>
    <Icon style={{ width: '50%' }} />
  </div>
);
