import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Button, Container } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import ElevationScroll from './ElevationScroll';
import { SSLogo } from '../../images';

const isSafari = () => navigator.userAgent.toLowerCase().indexOf('safari/') > -1;

const useStyles = makeStyles(theme => ({
  links: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  navLink: {
    textDecoration: 'none',
    color: theme.palette.link,
    margin: 'auto 30px',
    '&.active': {
      color: theme.palette.primary.main,
    },
  },
  logo: {
    height: theme.spacing(5),
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    padding: 0,
  },
  registrationLinks: {
    '& > a': {
      textDecoration: 'none',
      '& .MuiButtonBase-root': {
        minWidth: 'initial',
        height: !isSafari() && theme.spacing(4),
        '& .MuiButton-label': {
          height: isSafari() && theme.spacing(1.25),
        },
      },
    },
    '& > *:not(:first-child)': {
      marginLeft: 12,
    },
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <ElevationScroll>
      <AppBar position="sticky" color="inherit">
        <Container maxWidth="lg">
          <Toolbar className={classes.toolbar}>
            <div className={classes.links}>
              <a
                href={`${process.env.REACT_APP_LANDING_SITE_URL}/`}
                rel="noopener noreferrer"
                className={classes.logoLink}
              >
                <SSLogo className={classes.logo} />
              </a>
              <a
                href={`${process.env.REACT_APP_LANDING_SITE_URL}/`}
                rel="noopener noreferrer"
                className={classes.navLink}
              >
                Home
              </a>
              <a
                href={`${process.env.REACT_APP_LANDING_SITE_URL}/how`}
                rel="noopener noreferrer"
                className={classes.navLink}
              >
                How it works
              </a>
            </div>
            <div className={classes.registrationLinks}>
              <NavLink to="/signup" title="Sign Up">
                <Button color="primary" variant="contained">
                  Sign Up
                </Button>
              </NavLink>
              <NavLink to="/login" title="Login">
                <Button color="primary" variant="outlined">
                  Login
                </Button>
              </NavLink>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
};
