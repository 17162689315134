import { isBefore, differenceInCalendarDays } from 'date-fns';

const getUserOrViewingUser = user => {
  if (user?.isAdmin && Boolean(user?.viewingUser)) {
    return user.viewingUser;
  }
  return user;
};

export const isLoggedIn = ({ user }) => {
  if (user === false) return false;
  if (!!user && !!user.uid) return true;
  return undefined;
};

export const getUser = ({ user }) => {
  return getUserOrViewingUser(user);
};

export const isBillingValid = ({ user }) => {
  try {
    const {
      plan: { billingVerified, trialEnds },
    } = getUserOrViewingUser(user);

    if (!!billingVerified) {
      //user is billing verified
      return true;
    }

    if (!!trialEnds && isBefore(new Date(), trialEnds.toDate())) {
      //user is on an active trial
      return true;
    }

    return false;
  } catch (e) {
    return false;
  }
};

export const daysLeftInTrial = ({ user }) => {
  try {
    const {
      plan: { trialEnds },
    } = getUserOrViewingUser(user);

    if (!trialEnds) return false;

    return differenceInCalendarDays(trialEnds.toDate(), new Date());
  } catch (e) {
    return false;
  }
};

export const daysLeftInSubscription = ({ user }) => {
  try {
    const {
      plan: { endsAt },
    } = getUserOrViewingUser(user);

    if (!endsAt) return false;

    return differenceInCalendarDays(endsAt.toDate(), new Date());
  } catch (e) {
    return false;
  }
};
