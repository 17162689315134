import { useEffect, useRef, useState } from 'react';

const INTERSECTION_OBSERVER_SUPPORTED = !!window.IntersectionObserver;

export default ({ root = null, rootMargin, threshold = 0 }) => {
  if (!INTERSECTION_OBSERVER_SUPPORTED) return [() => {}, {}, INTERSECTION_OBSERVER_SUPPORTED];

  const [entry, updateEntry] = useState({});
  const [node, setNode] = useState(null);

  const observer = useRef(
    new window.IntersectionObserver(([entry]) => updateEntry(entry), {
      root,
      rootMargin,
      threshold,
    })
  );

  useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();

    if (node) currentObserver.observe(node);

    return () => currentObserver.disconnect();
  }, [node]);

  return [setNode, entry, INTERSECTION_OBSERVER_SUPPORTED];
};
