import React, { useState } from 'react';
import { Typography, OutlinedInput, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { auth } from '../../firebase';
import useAsync from '../../hooks/useAsync';
import FormButtons from './FormButtons';

const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: 330,
    minHeight: 350,
    height: 350,
    padding: theme.spacing(4, 5),
    margin: '0 auto',
  },
  inputBox: {
    borderRadius: 8,
    fontSize: '.9rem',
    height: 43,
    marginTop: theme.spacing(1.4),
  },
  inputSection: {
    margin: 'auto 0',
    paddingTop: theme.spacing(2),
  },
  messageSpace: {
    height: 50,
  },
  success: {
    color: 'green',
    marginTop: theme.spacing(2.5),
  },
  error: {
    marginTop: theme.spacing(0.5),
  },
  form: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const requestPasswordReset = async email => {
  await auth().sendPasswordResetEmail(email);

  return { message: 'Email Sent.' };
};

export default () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [{ isLoading, result, error }, doReset] = useAsync(requestPasswordReset);

  return (
    <Paper elevation={0} variant="outlined" className={classes.paper}>
      <form
        className={classes.form}
        onSubmit={e => {
          e.preventDefault();
          doReset(email);
        }}
      >
        <Typography variant="h5" align="center">
          Reset your Password
        </Typography>
        <div className={classes.inputSection}>
          <Typography variant="body1">Username</Typography>
          <OutlinedInput
            className={classes.inputBox}
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Enter email address"
            fullWidth
            required
          />
        </div>
        <div className={classes.messageSpace}>
          {!!result && !isLoading && !error && (
            <Typography className={classes.success} variant="subtitle2" align="center">
              {result.message}
            </Typography>
          )}
          {!!error && (
            <Typography className={classes.error} variant="subtitle2" color="error">
              We can’t locate that email. Please contact{' '}
              <a href="mailto:support@safeshepherd.com">support@safeshepherd.com</a> for assistance or try a different
              email
            </Typography>
          )}
        </div>
        <div className={classes.btnSection}>
          <FormButtons
            className={classes.btnSection}
            isLoading={isLoading}
            primaryText={'Send Recovery Email'}
            secondaryText={'Go to Login'}
            secondaryPath={'/login'}
          />
        </div>
      </form>
    </Paper>
  );
};
