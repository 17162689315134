import { db } from '../firebase';
import { useState, useEffect } from 'react';

const constructQuery = (collectionName, where, order) => {
  // Assuming it's querying processed collections...
  let query = db.collection(collectionName);

  if (order) {
    query = query.orderBy(order);
  }

  if (!where) {
    return query;
  } else if (typeof where[0] === 'string' && where.length === 3) {
    // if `where` is e.g. ['tournament.id', '==', '123]
    query = query.where(...where);
  } else if (Array.isArray(where) && where.every(w => w.length === 3)) {
    // if `where` is e.g. [['tournament.id', '==', '123], ['start', '<', new Date()]]
    for (let w of where) {
      query = query.where(...w);
    }
  } else {
    console.error('`where` format incorrect');
  }

  return query;
};

const normalize = arr =>
  arr.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {});

export default (collectionName, whereParams, order) => {
  const query = constructQuery(collectionName, whereParams, order);

  const [initialized, setInitialized] = useState(false);
  const [results, setResults] = useState({});

  useEffect(() => {
    const unsub = query.onSnapshot(snapshot => {
      if (!initialized) {
        const payload = snapshot.docs.map(d => {
          return { ...d.data(), id: d.id };
        });
        setResults(normalize(payload));
        setInitialized(true);
      } else {
        snapshot.docChanges().forEach(change => {
          const data = change.doc.data();
          if (change.type === 'removed') {
            const { [data.id]: removed, ...newResults } = results;
            setResults(newResults);
          } else {
            // added or modified
            setResults({ ...results, [data.id]: data });
          }
        });
      }
    });
    return unsub;
    //eslint-disable-next-line
  }, [collectionName + JSON.stringify(whereParams)]); // query should unsub & resub if anything pertaining to the query changes.

  //Initialized represent the isLoading state
  return [Object.values(results), !initialized];
};
